import axios from 'axios'
import { useState, useEffect} from 'react'
import { Flex, Flexed, Teir } from '../../shared'
import { productColumns } from '../components/Columns'
import Table from '../components/Table'
import Modal from '../../modals/Modal'
import Addproduct from './Addproduct'
import { BsPersonFillAdd } from 'react-icons/bs'



const Products = () => {

    const [DATA, setDATA] = useState([])
    const [list, setList] = useState('')
    const [customerform, setCustomerform] = useState(false)
    const [recalc, setRecalc] = useState(false)


useEffect(() => {
    window.scrollTo(0, 0);
 const getall = async() => {
await axios.get('https://mailpostapi.herokuapp.com/products/all')
.then(res => {console.log(res.data);setDATA(res.data)})
.catch(err => console.log(err))
 }

 getall()
},[recalc])

  return (
    <Teir>
<Flexed>
<Modal show={customerform} header={`Add New Product`} onCancel={() => {setCustomerform(false)}} ><Addproduct onCancel={() => {setCustomerform(false)}}  setRecalc={setRecalc} recalc={recalc}/></Modal>
  <Flex fontSize="calc(1.2rem + .5vw)" padding="10px 0px">MPS Products LIst - {DATA.length}</Flex>
  <div ><BsPersonFillAdd style={{color:"var(--plsGreen)",fontSize:"calc(1.8rem + .5vw)",position:"relative",top:"60px",zIndex:"5"}} onClick={() => setCustomerform(true)} /></div>
</Flexed>
<Table supplyColumns={productColumns} DATA={DATA} setter="products"/>
    </Teir>
  )
}

export default Products