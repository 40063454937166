import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Datepick } from '../../../shared'


const AddEvent = ({events,setEvents,onCancel}) => {
    const [oldvalues, setOldvalues] = useState(events)
    const [newvalues, setNewvalues] = useState({date:new Date()})

console.log(oldvalues)




function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }



const addjob = () => {

    const vals = {
        customer:newvalues.customer,
        date:newvalues.date,
        job:newvalues.job,
        hours:newvalues.hours,
        id:Math.random(),
        status:0
    }
 setEvents(events => [...events, vals])
 onCancel()

}
  return (
    <>
    <Flexed gap="10px">
        <Flex>
            <Label>Customer<br /></Label>
        <Input type="text" value={newvalues?.customer} onChange={e => setNewvalues({...newvalues,customer:e.target.value})}/>
        </Flex>
        <Flex>
            <Label>Date<br /></Label>
            <Datepick selected={newvalues?.date} onChange={(date) => setNewvalues({...newvalues,date:date})} />
        </Flex>
        <Flex>
            <Label>Job#<br /></Label>
        <Input type="text" value={newvalues?.job} onChange={e => setNewvalues({...newvalues,job:e.target.value})}/>
        </Flex>
        <Flex>
            <Label>Hours<br /></Label>
        <Input type="number" value={newvalues?.hours} onChange={e => setNewvalues({...newvalues,hours:e.target.value})}/>
        </Flex>
    </Flexed>
    {newvalues?.customer !== undefined && <Button onClick={e => addjob()}>Save</Button>}
    </>
  )
}

export default AddEvent