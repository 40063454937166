
import styled from 'styled-components'
import { bgChange} from '../../../utils/Utilities'


export const SchedulerWrapper = styled.div`
position:relative;
width:100%;
height:90vh;
border:1px solid #c0c0c0;
margin:5px;
display:flex;
gap:20px;
`;

export const CalenderHead = styled.div`
width:100%;
height:40px;
display:flex;
justify-content:space-around;
align-items:center;
font-size:calc(1rem + .4vw);
border:1px solid black;
padding:0px 15px;

`;

export const SevenCol = styled.div`
width:100%;

display:grid;
grid-template-columns:repeat(7,1fr);

`;


export const HeadDay = styled.span`
text-align:center;
height:100%;
flex:1;
background:darkkhaki;
font-size:calc(1.2rem + .4vw);


`;

export const CalNav = styled.div`
flex:1;
text-align:left;
display:flex;
justify-content:flex-end;
align-items:center;

`;

export const CalWrap = styled.div`
width:100%;
height:100%;


`;
export const CalList =  styled.div`
width:25%;
padding:5px;
border:1px solid red;
height:100%;
max-height:100%;
overflow-Y:scroll;
color:${props => props.color || bgChange(2)}

.notassigned{
    color:var(---plsBlue);
    background:#808080;
  
    
 
}


`;

export const CalenderBody = styled.div`
height:calc(100% - 27px - 40px);
display:grid;
grid-template-columns:repeat(7,1fr);
grid-template-rows:repeat(${({forthCol}) => forthCol ? 4 : 5});
`;

export const StyledDay = styled.span`
border:1px solid lightgrey;
padding:5px;

${({active}) => active && `background:pink`}

`;

export const StyledEvent = styled.div`

color:${props => props.color || "white"};
margin-bottom:5px;
font-size:calc(.7rem + .3vw);
background:${props => props.bgColor || "var(--plsBlue)"};
cursor:move;

`;

export const PortalWrapper = styled.div`
position:absolute;
width:100%;
height:100%;
right:0;
top:0;
border:1px solid red;
background:rgb(0,0,0,.2);
`;

export const PortalInner = styled.div`
background:white;
position:absolute;
width:60%;

top:50%;
left:50%;
border:1px solid;
border-radius:8px;
transform:translate(-50%, -50%);
box-shadow:10px 10px 20px black;
z-index:20;
padding:10px;
border:1px solid blue;
`;

