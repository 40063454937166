import axios from 'axios'
import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Addproduct = ({onCancel,recalc,setRecalc}) => {

   
const [customer, setCustomer] = useState({ 
    description:"",
    sku:"",
    vendor:"",
    cost:"",
     msrp:"",
    inventory:"",
    onhand:"PLS",
    reorderlevel:"No",
})





const submit = (e) => {
 e.preventDefault()

 console.log(customer)
axios.post('https://mailpostapi.herokuapp.com/products/add',customer)
//axios.post('http://localhost:5004/products/add',customer)
.then(res => {setRecalc(!recalc);onCancel()})
.catch(error => toast.error(`${error.response.error.message}`))
 
}

  return (
    <Wrap>
        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">  <Label>Product</Label><br /><Input type="text" placeholder="Product Name" value={customer.description} onChange={e => setCustomer({...customer,description:e.target.value})} required/></Flex>
<Flex textAlign="left"  flex="1.5">  <Label>SKU</Label><br /><Input type="text" placeholder="SKU" value={customer.sku} onChange={e => setCustomer({...customer,sku:e.target.value})} required/></Flex>

    </Flexed >



    <Flexed gap="15px" padding="20px 0px 0px 0px" >
    <Flex textAlign="left"  flex="2">
<Label>Vendor</Label><br />
    <Input value={customer.vendor} onChange={e => setCustomer({...customer,vendor:e.target.value})}/>
</Flex>
        <Flex>
        <Label>Cost</Label><br />
    <Input value={customer.cost} onChange={e => setCustomer({...customer,cost:e.target.value})} required/>
    </Flex>
    <Flex>
    <Label>Price</Label><br />
    <Input value={customer.msrp} onChange={e => setCustomer({...customer,msrp:e.target.value})} required/>
    </Flex>
    <Flex flex="2"></Flex>
    </Flexed>


<Flexed padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex textAlign="right"><Button type="submit">Save New Product</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default Addproduct
