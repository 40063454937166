import React from 'react'
import { Input } from '../../shared'

const ColumnFilter = ({column}) => {
    const {filterValue, setFilter} = column
    return (
        <span>
            <Input type="text" value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder="Filter"
            style={{padding:"4px 4px 4px 4px",fontSize:"calc(.6rem + .5vw)",width:"100%"}}
            />
    
        </span>
      )
    }

export default ColumnFilter