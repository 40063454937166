import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { Button, Datepick, Flex, Flexed, Input, Label, PhoneNumber, Scroll, Selectbox, Teir, Textarea, Wrap, decimal} from "../../shared"
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import SuppliesComponent from "../components/print/SuppliesComponent";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import uuid from 'react-uuid';
import LeasePrint from '../components/print/LeasePrint'




const Supplies = ({setReset,reset}) => {

const custid = useParams()
const navigate = useNavigate()
const [purchaseInfo, setPurchaseInfo] = useState({})
const [selectedaddress, setSelectedaddress] = useState({})
const [selectedaddress2, setSelectedaddress2] = useState({})
const [selectedCustomer, setSelectedCustomer] = useState({})
const [currentItem, setCurrentItem] = useState({})
const [items, setItems] = useState([])
const [recalc, setRecalc] = useState(false);
const [total, setTotal] = useState(0);
const [labor, setLabor] = useState(0);
const itemInitial = {
  quantity:null
}



useEffect(() => {

    window.scrollTo(0, 0);
  const getorder = async() => {
    await axios.get(`https://mailpostapi.herokuapp.com/customers/${custid.id}`)
      .then(res => {
       setSelectedCustomer(res.data); 
      // setItems(res.data.items)
       //setSelectedaddress2({label:res.data.shipingaddress,value:0}); 
     })
      .catch(err => console.log(err))
   }
   const getProducts = async() => {  
    await axios.get(`https://mailpostapi.herokuapp.com/products/all`)
    .then(res => {setProductlist(res.data.map(item => ({value:item._id,label:item.sku+" - "+item.description})))})
    .catch(err => {
        console.log(err)
    })
}

 getorder()
 getProducts()
 setItems(items)
},[recalc])





useEffect(() => {
  const getProduct = async() => {
    await axios.get(`https://mailpostapi.herokuapp.com/products/item/${currentItem.product}`)
    .then(res => {setCurrentItem({...currentItem,price:res.data.msrp,sku:res.data.sku,cost:res.data.cost,})})
    .catch(err => {
       
    })
  }
  getProduct()
},[currentItem.product])


useEffect(() => {
  let t
  loadPrices()
},[currentItem.quantity,currentItem.cost,currentItem.price])





const loadPrices = () => {

  let quantity = currentItem.quantity || 0
  let margin = currentItem.price - currentItem.cost || 0
  let extendecost = currentItem.quantity * currentItem.cost || 0
  let extendedmargin = margin * currentItem.quantity || 0
  let extendedtotal = currentItem.price * currentItem.quantity || 0

  
  setCurrentItem({...currentItem,extendecost:extendecost.toFixed(2),extendedmargin:extendedmargin.toFixed(2),extendedtotal:extendedtotal.toFixed(2),margin:margin.toFixed(2),quantity:quantity})
}


const updateItem = (id,feild,value) => {

  const updateinfo = {
    id,
    feild,
    value
  }
     // axios.post(`https://mailpostapi.herokuapp.com/supplies/update`,newadd)
      axios.post(`https://mailpostapi.herokuapp.com/products/updateitem`, updateinfo)
      .then(response => {toast.success(`${currentItem.productName} - ${feild} has been updated`)})
      .catch(err => console.log(err))
}










    const initialitem = {
        quantity: null,
         msrp:null,
       cost: null
 }
    const initialItem = {}
const [sheettype, setShettype] = useState("")
const [vendorList, setVendorList] = useState([])
const [selectedcustomer, setSelectedcustomer] = useState({})
const [addresslist, setAddresslist] = useState([])

const [sameas, setSameas] = useState(false)
const [productlist, setProductlist] = useState([])
const [currentproductid, setCurrentproductid] = useState({})
const [maintanenceid, setMaintanenceid] = useState({})
const [fundingid, setFundingid] = useState({})
const [fundingleaseterm, setFundingleaseterm] = useState({})
const [currentproduct, setCurrentproduct] = useState(initialitem)
const [selecteditem, setSelecteditem] = useState({})
const [extended, setExtended] = useState([])
const [maintanence, setMaintanence] = useState({})
const [funding, setFunding] = useState({})
const [notify, setNotify] = useState({})
const [amountdown, setAmountdown] = useState(null)
const [balance, setBalance] = useState(null)
const [purchases, setPurchases] = useState([])
const [thirdparty, setThirdparty] = useState([])
const [addfunding, setAddfunding] = useState(false)
const [supplies, setSupplies] = useState([])
const [type, setType] = useState('')
const day = new Date()  
const [list, setList] = useState([])
const [orderid, setOrderid] = useState(null)
const [itemadding, setItemadding] = useState({})

const [saving, setSaving] = useState({})



const [supplyinfo, setSupplyinfo] = useState({})
const leaseCompanies = [
    {label:"GALC – Great America Leasing Corp",value:"GALC – Great America Leasing Corp"},
    {label:"DLL – De Lage Landen Financial Services, Inc",value:"DLL – De Lage Landen Financial Services, Inc"}
]
const leaseendstime = [
    {label:"1 Month",value:"1"},
    {label:"2 Month",value:"2"},
    {label:"3 Month",value:"3"},
    {label:"4 Month",value:"4"},
    {label:"5 Month",value:"5"},
    {label:"6 Month",value:"6"},
    {label:"7 Month",value:"7"},
    {label:"8 Month",value:"8"},
    {label:"9 Month",value:"9"},
    {label:"10 Month",value:"10"},
    {label:"11 Month",value:"11"},
    {label:"12 Month",value:"12"}
]


const maintenancetime = [
    {label:"None",value:"None"},
    {label:"12 Months",value:"12"},
    {label:"24 Months",value:"24"},
    {label:"36 Months",value:"36"}

]
const bookOptions = [
    {label:"Purchase",value:"Purchase"},
    {label:"Rental",value:"Rental"},
    {label:"Lease",value:"Lease"},
    {label:"3rd Party",value:"3rd Party"}
]

const customerid = useParams();

useEffect(() => {
  if(sameas){
      setPurchaseInfo({...purchaseInfo,shipingaddress:purchaseInfo.billingaddress})
  }else{
    setPurchaseInfo({...purchaseInfo,shipingaddress:''})
  }
  },[sameas])


useEffect(() => {
setLabor(purchaseInfo.laborcost * purchaseInfo.laborhours)
},[purchaseInfo.laborcost,purchaseInfo.laborhours])






const deleteItem = async(value) => {
  console.log(value)

  const newadd = {
    billingaddress:purchaseInfo.billingaddress,
     shipingaddress:purchaseInfo.shipingaddress,
      id:purchaseInfo._id,
      items:items.filter((item,index) => index !== value),
      orderdate:new Date(), 
      freightcharge:purchaseInfo.freightcharge,
      laborcost:purchaseInfo.laborcost,
      laborhours:purchaseInfo.laborhours,
      notes:purchaseInfo.notes
  }
  /*axios.post(`https://mailpostapi.herokuapp.com/supplies/update`,newadd)
  .then( res => {
    console.log(res)
setRecalc(!recalc)


  })
  .catch(err => {
  console.log(err)
  })*/
  setItems(items.filter((item,index) => index !== value))
  toast.success(`Item Deleted`)
}


useEffect(() => {
    let newtotal
    let monthly = 0

    const mant = () => {
        let le
        let mo
       // if(addfunding){
           switch(currentItem.maintenanceterms){
               case "12 Months":
               le = 1
               mo = 12
               break;
               case "24 Months":
                   le = 2
                   mo = 24
               break;
               case "36 Months":
                   le = 3
                   mo = 36
               break;
               default:
                le = 0
                   }
      
                newtotal = parseInt(currentItem.annual) * le
               // console.log(maintanence.terms)
     monthly = newtotal / mo

     if(isNaN(newtotal)){newtotal = 0}
  
     if(isNaN(monthly)){monthly = 0}
               setCurrentItem({...currentItem,total:newtotal,monthly:monthly})
             

    }
    mant()
},[currentItem.maintenanceterms,currentItem.annual])





const getbilladdress = (selectedOption) => {
    setSelectedaddress(selectedOption)
}

const productchange = (selectedOption) => {
    setCurrentproductid(selectedOption.value)
   
   // figure()
}




const customStyles = {
    control: base => ({
      ...base,
      height: 30,
      minHeight: 30,
 
    })
  };


  useEffect(() => {
    let totalt = 0;
    const get = () => {
      items.map(item => {
        totalt += parseFloat(item.extendedtotal)
      })
      setTotal(totalt)
    }
   get()
  },[items])




const addItem = (e,value,index) => {
  e.preventDefault();

  let newitem= {
    productName:currentItem.productName,
            quantity:currentItem.quantity,
            product:currentItem.product,
            sku:currentItem.sku,
            price:currentItem.price,
            cost:currentItem.cost,
            margin:currentItem.margin,
            extendecost:currentItem.extendecost,
            extendedmargin:currentItem.extendedmargin,
            extendedtotal:currentItem.extendedtotal,
            newid:uuid()
}  

let t

items.map(item => {
  t += item.extendedtotal

    })


if(currentItem.newid){
  const objIndex = items.findIndex((obj => obj.newid === currentItem.newid));
let newt = [...items]
  newt[objIndex] = newitem

setItems(newt)
setCurrentItem({})

  toast.success(`Item Updated`)
}else{
    setItems([...items, newitem]);
    setCurrentItem({})

  toast.success(`Item Added`)
}

}

useEffect(() => {

  let rate = parseFloat(purchaseInfo.leaserate)

  let amount = parseFloat(purchaseInfo.leaseamount)

  const mant = () => {
     
             let temp = Math.round(10*(amount * rate))/10;
  
let newtemp = amount + temp
let monthly = newtemp / parseInt(purchaseInfo.leasetermsvalue)
setPurchaseInfo({...purchaseInfo,leasemonthly:monthly,leasetotal:newtemp})
  }
  mant()
},[purchaseInfo.leaserate,purchaseInfo.leaseamount,purchaseInfo.leaseterms])



const saveAll = () => {
    let newadd ={
    billingaddress:purchaseInfo.billingaddress,
    customer:custid.id,
    financecompany:purchaseInfo.financecompany,
    freightcharge:purchaseInfo.freightcharge,
    items:items,
    leaseamount:purchaseInfo.leaseamount,
    leasemonthly:purchaseInfo.leasemonthly,
    leaserate:purchaseInfo.leaserate,
    leaseterms:purchaseInfo.leaseterms,
    leasetermsvalue:purchaseInfo.leasetermsvalue,
    leasetotal:purchaseInfo.leasetotal,
    notes:purchaseInfo.notes,
    orderdate:new Date(),
    shipingaddress:purchaseInfo.shipingaddress,
    }



    console.log(newadd)
    console.log(purchaseInfo)
     axios.post(`https://mailpostapi.herokuapp.com/leases/add`,newadd)
    //axios.post(`https://mailpostapi.herokuapp.com/leases/add`,newadd)
    .then( res => {
      console.log(res.data)
      setCurrentItem({})

      toast.success(`Purchase Saved`)
  //navigate(`/customer/purchase/${res.data._id}`)

 

    })
    .catch(err => {
    console.log(err)
    })

    
  }

/*
 const objIndex = items.findIndex((obj => obj.id === selectedItem.id));
                  items[objIndex] = selectedItem
*/

console.log(purchaseInfo)
  return (
    <Teir marginTop="1%">
        <ToastContainer position="bottom-center" theme="dark"/>
       
        <form>
            <Flexed >
                <Flex fontSize="calc(1.6rem + .5vw)">MPS New Lease Order</Flex>
            </Flexed>
   
            <Flexed gap="20px" padding="20px 0px">
                <Flex  flex="2.5" fontSize="calc(1.5rem + .5vw)" textAlign="left" padding="10px 0px 0px 0px">{selectedCustomer?.name}
               </Flex>
               <Flex flex="1.5" style={{marginTop:"8px",fontSize:"1.4rem"}}> <span style={{fontSize:"2rem"}}>{purchaseInfo.supplyorderid}</span></Flex>
<Flex flex="1"> Today Date:<br /><Datepick  fontWeight="bold" fontSize="calc(.6rem + .5vw)"  margin="0px 0px 5px 0px" selected={day} onChange={e => console.log('nada')} /></Flex>
<Flex flex="1">Delivery Date:<br /><Datepick  fontWeight="bold" fontSize="calc(.6rem + .5vw)"  margin="0px 0px 5px 0px" selected={day} onChange={e => console.log('nada')} /></Flex>
</Flexed>

<Flexed  gap="20px" margin="0px 0px 20px 0px">
    <Flex textAlign="left">Billing Address
<Select  
        value={{label:purchaseInfo.billingaddress, value:0}}
        onChange={selectedOption => setPurchaseInfo({...purchaseInfo,billingaddress:selectedOption.label,value:selectedOption.value})}
        options={selectedCustomer?.address?.map((item, index) => ({"label":item.address1+" "+item.address2+" "+item.city+" "+item.state+" "+item.zip, "value":index}))}
        styles={customStyles}
      required={true}/></Flex>
<Flex flex=".5">Attn<Input type="text"  /></Flex>
</Flexed>
<Flexed gap="20px" margin="0px 0px 20px 0px">
     <Flex textAlign="left">Shipping Address&nbsp;<div style={{fontSize:"9pt",position:"absolute",left:"200px",bottom:"35px",display:"flex",width:"200px",alignItems:"center"}}>Same as Billing&nbsp;&nbsp;<Input type="checkbox" height="15px" width="15px" checked={sameas} onChange={e => setSameas(!sameas)}/></div><Select 
       value={{label:purchaseInfo.shipingaddress, value:0}}
       onChange={selectedOption => setPurchaseInfo({...purchaseInfo,shipingaddress:selectedOption.label,value:selectedOption.value})}
       options={selectedCustomer?.address?.map((item, index) => ({"label":item.address1+" "+item.address2+" "+item.city+" "+item.state+" "+item.zip, "value":index}))}
        styles={customStyles}
        isDisabled={sameas}/></Flex>
      <Flex  flex=".5">Attn<Input type="text" defaultValue={PhoneNumber(selectedcustomer?.mainphone)} /></Flex>
</Flexed>

  <hr style={{margin:"30px 0px 20px 0px"}} />
  

<Flexed gap="20px">

    <Flex flex="4" textAlign="left"><Label>Product</Label><br /><Select

value={{label:currentItem.productName,value:currentproduct.product}}
onChange={selectedOption => setCurrentItem({...currentItem,productName:selectedOption.label,product:selectedOption.value})}
options={productlist}
styles={customStyles}

                    /></Flex>
                    <Flex flex=".5"></Flex>
    <Flex textAlign="left" flex=".8"><Label>SKU#</Label><br /><Input type="text" defaultValue={currentItem?.sku} disabled={true} /></Flex>
</Flexed>




<Flexed gap="20px" margin="20px 0px 0px 0px">  
<Flex flex="1" textAlign="left"><Label>Quantity</Label><br /><Input type="text" value={currentItem?.quantity} onChange={e => setCurrentItem({...currentItem,quantity:e.target.value})}/></Flex>

    <Flex flex="1" textAlign="left"><Label>Price</Label><br /><Input type="text" defaultValue={currentItem?.price || null} onChange={e => setCurrentItem({...currentItem,price:e.target.value})}/><br /><span style={{display:"flex",justifyContent:"flex-end",textAlign:"right",width:"100%"}}><Button type="button"  onClick={e => updateItem(currentItem.product,"msrp",currentItem.price)}>Update</Button></span></Flex>

    <Flex flex="1" textAlign="left"><Label>Cost</Label><br /><Input type="text" defaultValue={currentItem?.cost} onChange={e => setCurrentItem({...currentItem,cost:e.target.value})}/><br /><span style={{display:"flex",justifyContent:"flex-end",textAlign:"right",width:"100%"}}><Button type="button"  onClick={e => updateItem(currentItem.product,"cost",currentItem.cost)}>Update</Button></span></Flex>
    <Flex flex="1" textAlign="left"><Label>Margin</Label><br /><Input type="text" defaultValue={currentItem?.margin}  /></Flex>
    <Flex flex="1" textAlign="left"><Label>Ext. Margin</Label><br /><Input type="text" defaultValue={currentItem?.extendedmargin} disabled={true} /></Flex>
    <Flex flex="1" textAlign="left"><Label>Ext. Cost</Label><br /><Input type="text" defaultValue={currentItem?.extendecost} disabled={true} /></Flex>
    <Flex flex="1" textAlign="left"><Label>Ext. Price</Label><br /><Input type="text" defaultValue={currentItem?.extendedtotal} disabled={true} /></Flex>
</Flexed>



<Flexed gap="20px" padding="20px 0px 0px 0px">
    <Flex flex="2" textAlign="left"><Label>Maintenance Terms</Label><br />
    <Select
value={{label:currentItem.maintenanceterms,value:currentproduct.maintenanceterms}}
    onChange={selectedOption => setCurrentItem({...currentItem,maintenanceterms:selectedOption.label,value:selectedOption.value})}
    options={maintenancetime}
    styles={customStyles}
    /></Flex>
    
    <Flex flex="1" textAlign="left"><Label> Annual</Label><br /><Input type="number" value={currentItem?.annual} onChange={e => setCurrentItem({...currentItem,annual:e.target.value})}/></Flex>
    
    <Flex flex="1" textAlign="left"><Label>Monthly </Label><br /><Input type="text" value={currentItem.monthly?.toFixed(2)} disabled={true}/></Flex>
    <Flex flex="1"  textAlign="left" display="flex" justifyContent="center" alignItems="center"></Flex>
    <Flex flex="1" textAlign="left"><Label>Total </Label><br /><Input type="text" value={currentItem.total?.toFixed(2)} disabled={true}/><br /><span style={{paddingTop:"5px",fontSize:"calc(.4rem + .5vw)"}}><Input type="checkbox" height="15px" width="15px" checked={addfunding} onChange={e => setAddfunding(!addfunding)}/>&nbsp;&nbsp;Add To Funding</span></Flex>
    
</Flexed>

<Flexed style={{borderBottom:"1px solid #303030"}}>
<Flex display="flex" justifyContent="flex-end" textAlign="right"  padding="20px 0px"><Button type="button" style={{marginRight:"30px" }} onClick={e => setCurrentItem({})} >Cancel</Button>{currentItem.quantity > 0 && currentItem.product > "" && <Button type="button" onClick={addItem} >{!currentItem.newid  ? 'Add Item' : 'Update Item'}</Button>}</Flex>
</Flexed>

<Flexed gap="20px" padding="20px 0px">
    <Flex flex="2" textAlign="left"><Label>Finance Company</Label><br /><Select

value={{label:purchaseInfo.financecompany,value:purchaseInfo.financecompany}}
onChange={selectedOption => setPurchaseInfo({...purchaseInfo,financecompany:selectedOption.label})}
    options={leaseCompanies}
    styles={customStyles}


/></Flex>
<Flex textAlign="left"><Label>Lease Terms</Label><br />
<Select

value={{label:purchaseInfo.leaseterms,value:purchaseInfo.leasetermsvalue}}
    onChange={selectedOption => {setPurchaseInfo({...purchaseInfo,leaseterms:selectedOption.label,leasetermsvalue:selectedOption.value})}}
    options={maintenancetime}
    styles={customStyles}
    />
    
    </Flex>
    </Flexed>
    <Flexed gap="20px" padding="10px 0px">
    <Flex flex="1" textAlign="left"><Label>Funding Amount</Label><br /><Input type="number" value={purchaseInfo.leaseamount} onChange={e => setPurchaseInfo({...purchaseInfo,leaseamount:e.target.value})} /></Flex>
    <Flex flex="1" textAlign="left"><Label>Rate</Label><br /><Input type="number" step=".0001" min="0" value={purchaseInfo.leaserate} onChange={e => setPurchaseInfo({...purchaseInfo,leaserate:e.target.value})} /></Flex>
    <Flex flex="1" textAlign="left"><Label>Total</Label><br /><Input type="number" value={purchaseInfo.leasetotal?.toFixed(2)} disabled={true}/></Flex>
    <Flex flex="1" textAlign="left"><Label>Monthly Payment</Label><br /><Input type="number" defaultValue={purchaseInfo.leasemonthly?.toFixed(2)} disabled={true}/></Flex>
    <Flex flex="1" textAlign="left"></Flex>
</Flexed>








<Flexed padding="20px 0px" alignItems="flex-start" ><Flex flex="2.5" textAlign="left"><Label>Notes</Label><br /><Textarea padding="10px 5px" style={{boxSizing: "content-box"}}  onChange={e => setPurchaseInfo({...purchaseInfo,notes:e.target.value})}  minRows="4" value={purchaseInfo.notes} /></Flex>
<Flex flex="1" textAlign="left">
  <div style={{display:"flex",justifyContent:"space-between"}}>
  <div style={{width:"30%",display:"inline-block"}}><Label>Labor Hrs</Label><br /><Input onChange={e => setPurchaseInfo({...purchaseInfo,laborhours:e.target.value})} type="text" defaultValue={purchaseInfo?.laborhours}  /></div>
<div style={{width:"30%",display:"inline-block"}}><Label>Per Hr</Label><br /><Input onChange={e => setPurchaseInfo({...purchaseInfo,laborcost:e.target.value})} type="text" defaultValue={purchaseInfo?.laborcost}  /></div>
<div style={{width:"30%",display:"inline-block"}}><Label>Labor $</Label><br /><Input value={labor || 0} type="text"   /></div>
</div>
<div style={{display:"flex",justifyContent:"space-between"}}>
<div style={{width:"30%",display:"inline-block",paddingTop:"10px"}}><Label>Frieght Charge</Label><br /><Input type="text" defaultValue={purchaseInfo?.freightcharge} onChange={e => setPurchaseInfo({...purchaseInfo,freightcharge:e.target.value})} /></div>
<div style={{width:"45%",display:"inline-block",paddingTop:"10px"}}><br /><br />&nbsp;&nbsp;<Button type="button" onClick={saveAll}>Save Lease</Button></div>
<div style={{width:"30%",display:"inline-block",paddingTop:"30px",display:"flex",justifyContent:"center",alignItems:"center"}}>{purchaseInfo && <LeasePrint info={purchaseInfo} total={total} />}</div></div>
</Flex>

</Flexed>






</form>

<hr />

<div style={{paddingTop:"20px"}}>
<Flexed bgColor="var(--plsGrey)" padding="5px">
<Flex flex="1" textAlign="left">Sku#</Flex>
    <Flex flex="4" textAlign="left">Product</Flex>
    <Flex flex="1" textAlign="left">Quantity</Flex>
    <Flex flex="1" textAlign="left"></Flex>
    <Flex flex="1" textAlign="left"></Flex>
</Flexed>

    {items?.map((item,index) => (
        <Flexed key={index} padding="5px" >
               <Flex flex="1" textAlign="left" onClick={e => {setCurrentItem(item)}}>{item.sku}</Flex>
        <Flex flex="4" textAlign="left" onClick={e => {setCurrentItem(item)}}>{item.productName}</Flex>
        <Flex flex="1" textAlign="left" onClick={e => {setCurrentItem(item)}}>{item.quantity}</Flex>
    <Flex flex="1" textAlign="left" onClick={e => {setCurrentItem(item)}}></Flex>
    <Flex flex="1" textAlign="left"><Button onClick={e => deleteItem(index)}>Delete</Button></Flex>
 
    
    </Flexed>
    ))}

</div>

<br />
<br />
<br />
<br />
<br />

    </Teir>

  )
}

export default Supplies

