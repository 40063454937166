import {useState} from 'react'
import { Flexed, Input, Flex,Label, Textarea, Button} from '../../shared'
import axios from 'axios'
import States from '../components/States'

const AddContact = (props) => {

    const [contacts,setLocations] = useState({})

console.log(props.id)
const saveall = (e) => {
    let info = {'contacts':contacts,'id':props.id}
    e.preventDefault()
axios.post('https://mailpostapi.herokuapp.com/customers/add/contact',info)
//axios.post('http://localhost:5004/customers/add/contact',info)
.then(res => {console.log(res);props.setRecalc(!props.recalc);props.onCancel()})
.catch(err => console.log(err))
}

  return (
    <form onSubmit={saveall}>
    <Flexed gap="10px" padding="10px 0px">
        <Flex textAlign="left"><Label>Name:</Label><br /><Input value={contacts.name} onChange={e => setLocations({...contacts,name:e.target.value})} required/> </Flex>
        <Flex textAlign="left"><Label>Title</Label><br /><Input value={contacts.title} onChange={e => setLocations({...contacts,title:e.target.value})} required/> </Flex>
        <Flex textAlign="left"><Label>Email</Label><br /><Input value={contacts.email} onChange={e => setLocations({...contacts,email:e.target.value})} /> </Flex>
    </Flexed>

    <Flexed gap="10px" padding="10px 0px" >
         <Flex textAlign="left"><Label>Phone</Label><br /><Input selected="Florida" value={contacts.cell} onChange={e => setLocations({...contacts,cell:e.target.value})} required/> </Flex>
    <Flex textAlign="left"><Label>Ext</Label><br /><Input selected="Florida" value={contacts.ext} onChange={e => setLocations({...contacts,ext:e.target.value})} required/></Flex>
    <Flex textAlign="left" flex="3"><Label>Notes:</Label><br /><Textarea value={contacts.notes} onChange={e => setLocations({...contacts,notes:e.target.value})}></Textarea>
    </Flex>
    </Flexed>

    <Flexed justifyContent="flex-end" padding="20px 0px"><Button type="submit">Save Contact</Button></Flexed>
    </form>
  )
}

export default AddContact