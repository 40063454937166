import styled from 'styled-components'
import { Button, Flex, Img } from '../../shared';
import { currency } from  '../../shared'
import { useEffect, useState } from 'react';
import axios from 'axios';


export const Side = styled.div`
position:fixed;
min-height:100%;
width:15%;
top:8%;
left:0;
color:white;
background-color:#0E425A;
text-align:center;
padding:2vh 0px;

`;


const Sidemenu = ({allsales,allleasesales}) => {
console.log(allleasesales)
  
  return (
    <Side>

<u style={{fontSize:"calc(1rem + .3vw)"}}>Sales</u>
<div style={{textAlign:"center",fontWeight:"400",letterSpacing:"1px"}}>
<br />
<br />
<u>Today</u>
<br />
<div style={{position:"relative",padding:"5px 0px"}}>
  Purchase = {isNaN(allsales.today) ? 0.00 : currency(allsales.today)}<br />
  Lease = {isNaN(allleasesales.today) ? 0.00 : currency(allleasesales.today)}<br />
  Rental = {currency(0.00)}
  </div><br />


<u>This Week</u>
<br /><div style={{padding:"5px 0px"}}> 
Purchase = {isNaN(allsales.week) ? 0.00 : currency(allsales.week)}<br />
   Lease = {isNaN(allleasesales.week) ? 0.00 : currency(allleasesales.week)}<br />
  Rental = {currency(0.00)}</div><br />


<u>Last Week</u>
<br /><div style={{padding:"5px 0px"}}> 
Purchase = {isNaN(allsales.lastweek) ? 0.00 : currency(allsales.lastweek)}<br />
   Lease = {isNaN(allleasesales.lastweek) ? 0.00 : currency(allleasesales.lastweek)}<br />
  Rental = {currency(0.00)}</div><br />


<u>MTD</u>
<br /><div style={{padding:"5px 0px"}}> Purchase = {isNaN(allsales.month) ? 0.00 : currency(allsales.month)}<br />
   Lease = {isNaN(allleasesales.month) ? 0.00 : currency(allleasesales.month)}<br />
  Rental = {currency(0.00)}</div><br />


<u>QTD</u>
<br /><div style={{padding:"5px 0px"}}> Purchase = {isNaN(allsales.quarter) ? 0.00 : currency(allsales.quarter)}<br />
   Lease = {isNaN(allleasesales.quarter) ? 0.00 : currency(allleasesales.quarter)}<br />
  Rental = {currency(0.00)}</div><br />


<u>YTD</u><br />
<div style={{padding:"5px 0px"}}> Purchase = {isNaN(allsales.year) ? 0.00 : currency(allsales.year)}<br />
   Lease = {isNaN(allleasesales.year) ? 0.00 : currency(allleasesales.year)}<br />
  Rental = {currency(0.00)}</div>
</div>
    </Side>
  )
}

export default Sidemenu