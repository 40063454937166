
import { useTable, useSortBy, useGlobalFilter,useFilters } from 'react-table'
import {Flexed,Flex, Selectbox, Scroll} from '../../shared'
import { useMemo,useState,useEffect } from 'react'
import axios from 'axios'
//import DATA from './DATA.json'
import GlobalFilter from './GlobalFilter'
import styled from 'styled-components'
import { Link, useNavigate } from "react-router-dom";
import {MdAdd} from 'react-icons/md'


export const TableWrap = styled.table`
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
border:1px solid rgb(0,0,0,.2);
margin-top:10px;


th{
   padding:3px 0px;
  border-bottom:1px solid rgb(0,0,0,.2);
  text-align:left;

}
 
`;

export const TH = styled.th`
position:relative;
flex:${props => props.flex || "1"};
padding:5px;

word-break:break-all;
background-color:rgb(122,187,67,.3);

`;

export const TBody = styled.div`
width:100%;
max-height:350px;
overflow-y: scroll;
min-height:50px;

tr{
  width:100%;
   display:flex;
   :nth-child(even){background-color: #f2f2f2;}
}
tr:hover{
  cursor:pointer;
  background-color:rgb(122,187,67,.5);

 }
`;
export const THead = styled.div`
width:100%;

tr{
  width:100%;
   display:flex;
 
}

`;


export const TD = styled.td`
flex:${props => props.flex || "1"};
padding:5px;
word-break:break-all;
border:1px solid rgb(0,0,0,.2);
font-size:calc(.5rem + .5vw);


`;
const RecentTable = ({supplyColumns,DATA,setList,second,setter,addtext,onclick,oncancel}) => {

    let navigate = useNavigate();  


const columns = useMemo(() => supplyColumns, [])
const data = useMemo(() => DATA,[DATA])
const [paper, setPaper] = useState(false)




const TableInstance = useTable({
    columns,
    data
},
useFilters,
useGlobalFilter,
useSortBy)

const {getTableProps,getTableBodyProps,headerGroups,rows,prepareRow,state,setGlobalFilter} = TableInstance
const { globalFilter } = state




  return (
    <div style={{position:"relative"}}>
<Flexed justifyContent="space-between" alignItems="center" padding="10px 0px 0px 0px">
    <Flex flex="2"textAlign="left"> <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /></Flex>
   
</Flexed>
   

    <TableWrap {...getTableProps()}>
        <THead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TH  key={column.index} flex={column.Flex} {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
                  <span style={{position:"absolute",right:"5px",top:"1px"}}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TH>
              ))}
              <TH style={{flex:".15"}}></TH>
            </tr>
          ))}
        </THead>
       
        <TBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
            
              <tr {...row.getRowProps()} onClick={() => navigate(`/${setter}/${row.original._id}`)}>
                {row.cells.map(cell => {
                  return <TD style={{margin:"0px 0px",}} key={cell.index} flex={cell.render('Flex')} {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                })}
             </tr>
         
            )
          })}
        </TBody>
    
      </TableWrap>
     
      </div>
  )
}

export default RecentTable