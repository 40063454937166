
export const MONTHS = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"];

export const WEEKDAYS = ["Sun","Mon","Tue","Wed","Thur","Fri","Sat"];

export const bgChange = (item) => {
    let colors = {}
  switch(item){
  case "1" :
    colors.bgcolor="green"
    colors.color= "pink"
  break;
  case "2" :
    colors.bgcolor="#70AD47"
    colors.color= "white"
  break;
  case "3" :
    colors.bgcolor="green"
    colors.color= "white"
  break;
  case "4" :
    colors.bgcolor="#ED7D31"
    colors.color= "white"
  break;
  default:
    colors.bgcolor="grey"
    colors.color= "var(--plsBlue)"
    break;
}
  return colors
  }
  