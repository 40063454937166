import ColumnFilter  from './ColumnFilter'
import {Selectbox,currency} from '../../shared'
import { PhoneNumber } from '../../shared'
import Moment from 'react-moment';


export const supplyColumns = [
    {
        Header: 'Stockid',
        accessor: 'stockid',
        Filter: ColumnFilter,
        Flex:.5
    },
    {
        Header: 'Item Name',
        accessor: 'name',
        Filter: ColumnFilter,
        Flex:2
    },
    {
        Header: 'Category',
        accessor: 'category',
        Filter: ColumnFilter,
        Flex:1
    },
    {
        Header: 'Type',
        accessor: 'type',
        Filter: ColumnFilter,
        Flex:1,
        disableFilters:true
    },
    {
        Header: 'Cost',
        accessor: 'price',
        Filter: ColumnFilter,
        Flex:.8
    },
   
]

export const inkColumns = [
    {
        Header: 'Stockid',
        accessor: 'stockid',
        Filter: ColumnFilter,
        Flex:.5
    },
    {
        Header: 'Item Name',
        accessor: 'name',
        Filter: ColumnFilter,
        Flex:2
    },
    {
        Header: 'Cost',
        accessor: 'price',
        Filter: ColumnFilter,
        Flex:.8
    },
   
]

export const paperColumns = [

    {
        Header: 'Company',
        accessor: 'customer.name',
        Filter: ColumnFilter,
        Flex:1.5
    },
    {
        Header: 'Type',
        accessor: 'ordertype',
        Filter: ColumnFilter,
        Flex:.8
    },
    {
        Header: 'Quantity',
        accessor: '',
        Filter: ColumnFilter,
        Flex:.7,
        Cell: props => (props.value !== undefined && props.value+'"')
    },
    {
        Header: 'Date Ordered',
        accessor: ``,
        Filter: ColumnFilter,
        Flex:2,
   
    },
    {
        Header: 'Cost',
        accessor: '',
        Filter: ColumnFilter,
        Flex:.8,
        Cell: props => (isNaN(props.value) ? '' : currency(props.value))
    },
    {
        Header: 'Total Value',
        accessor: '',
        Filter: ColumnFilter,
        Flex:.8,
        Cell: props => currency(props.value)
    },
   
]


export const poColumns = [

    {
        Header: 'PO#',
        accessor: 'ponum',
        Filter: ColumnFilter,
        Flex:.4
    },
    {
        Header: 'Vendor',
        accessor: 'vendor.company.name',
        Filter: ColumnFilter,
        Flex:.4
    },
    {
        Header: 'Due Date',
        accessor: 'dueDate',
        Filter: ColumnFilter,
        Flex:.4
    },
    {
        Header: 'Ordered',
        accessor: 'createdAt',
        Filter: ColumnFilter,
        Flex:.4
    },
        {
          Header: 'Items', 
          accessor: 'items',
         
        },
    {
        Header: 'Cost',
        accessor: 'totalcost',
        Filter: ColumnFilter,
        Flex:.4
    },
]
/*
 stockid:{type:Number},
        name: {type:String},
        type: {type:String},
        color: {type:String},
        vendor:{type:mongoose.Schema.Types.ObjectId, ref: 'Vendor'},
        price: {type:Number},
    specific: {type:Number},
    press: {type:String},
    pressname: {type:String},
    defaultorderby: {type:String},
    defaultpriceunit: {type:String},
*/
export const customerColumns = [

    {
        Header: 'Id',
        accessor: 'company_id',
        Filter: ColumnFilter,
        Flex:.4
    },
    {
        Header: 'Customer',
        accessor: 'name',
        Filter: ColumnFilter,
        Flex:1.5,
    },
    {
        Header: 'Status',
        accessor: 'iscustomer',
        Filter: ColumnFilter,
        Flex:.5,
        Cell: props => (props.value === true ? <span style={{color:"#79BB43",fontWeight:"bold"}}>C</span>: <span style={{color:"#EA8D1F",fontWeight:"bold"}}>P</span>),
        disableFilters:true
    },
    {
        Header: 'Company',
        accessor: 'department',
        Filter: ColumnFilter,
        Flex:.7,
        disableFilters:true
    },
    {
        Header: 'DBA',
        accessor: 'dba',
        Filter: ColumnFilter,
        Flex:1.5,
    },
    {
        Header: 'Phone',
        accessor: 'mainphone',
        Filter: ColumnFilter,
        Cell: props => PhoneNumber(props.value),
        Flex:1,
   
    },
    {
        Header: 'Sales Rep',
        accessor: 'salesrep',
        Filter: ColumnFilter,
        Flex:.8
    },
  
    {
        Header: 'CSR',
        accessor: 'custrep',
        Filter: ColumnFilter,
        Flex:.8,
    },
]


export const employeeColumns = [
    {
        Header: 'Emp Id',
        accessor: 'empId',
        Filter: ColumnFilter,
        Flex:.4 
    },
    {
        Header: 'First',
        accessor: 'preferredName',
        Filter: ColumnFilter,
        Flex:.8  
    },
    {
        Header: 'Last',
        accessor: 'lastName',
        Filter: ColumnFilter,
        Flex:.8  
    },
    {
        Header: 'Email',
        accessor: 'workEmail',
        Filter: ColumnFilter,
        Flex:.8,
        Cell: props => <div onClick={(e) => {window.location = `mailto:${props.value}`}}>{props.value}</div>,
    },
    {
        Header: 'Ext',
        accessor: 'ext',
        Filter: ColumnFilter,
        Flex:.4  
    },
    {
        Header: 'Hire Date',
        accessor: 'hireDate',
        Filter: ColumnFilter,
        Cell: props => <Moment format="MM/DD/YYYY">{props.value}</Moment>,
        Flex:.8  
    },
]

export const recentColumns = [
    {
        Header: 'Customer',
        accessor: 'customer.name',
        Filter: ColumnFilter,
        Flex:1.5 
    },
    {
        Header: 'Order Id',
        accessor: 'supplyorderid',
        Filter: ColumnFilter,
        Flex:.5  
    },
    {
        Header: "Items",
        accessor: "items[]",
        Filter: ColumnFilter,
        Flex:3,
        Cell: ({ row }) => {
            return (
                 row.original.items
                    .map((item) => (
                        <div key={item._id}>
                            <>{item.product.description}</>
                        </div>
                    ))
            );
        },
        disableFilters:true
    },
    {
        Header: "Sale",
        accessor: "items",
        Filter: ColumnFilter,
        Flex:.8,
        Cell: ({ row }) => {
            return (
                 row.original.items
                    .map((item,index) => (
                        <div key={index}>
                            <>{currency(item.extendedtotal)}</>
                        </div>
                    ))
            );
        },
        disableFilters:true
    },
   
    {
        Header: 'Order Date',
        accessor: 'orderdate',
        Filter: ColumnFilter,
        Cell: props => <Moment format="MM/DD/YYYY">{props.value}</Moment>,
        Flex:.8  
    },
]

export const productColumns = [
    {
        Header: 'Sku',
        accessor: 'sku',
        Filter: ColumnFilter,
        Flex:.5 
    },
    {
        Header: 'Product',
        accessor: 'description',
        Filter: ColumnFilter,
        Flex:2  
    },
    {
        Header: 'Cost',
        accessor: 'cost',
        Filter: ColumnFilter,
        Flex:.5,
       
    },
    {
        Header: 'Price',
        accessor: 'msrp',
        Filter: ColumnFilter,
        Flex:.5,
        Cell: props => <div onClick={(e) => {window.location = `mailto:${props.value}`}}>{props.value}</div>,
    },
    {
        Header: 'Vendor',
        accessor: 'vendor',
        Filter: ColumnFilter,
        Flex:1  
    },
    
]


export const leaseColumns = [
    {
        Header: 'Customer',
        accessor: 'customer.name',
        Filter: ColumnFilter,
        Flex:1.5 
    },
    {
        Header: 'Order Id',
        accessor: 'leaseid',
        Filter: ColumnFilter,
        Flex:.5  
    },
    {
        Header: "Items",
        accessor: "items[]",
        Filter: ColumnFilter,
        Flex:3,
        Cell: ({ row }) => {
            return (
                 row.original.items
                    .map((item) => (
                        <div key={item._id}>
                            <>{item.productName}</>
                        </div>
                    ))
            );
        },
        disableFilters:true
    },
    {
        Header: "Term",
        accessor: "leaseterms",
        Filter: ColumnFilter,
        Flex:.8,
        disableFilters:true
    },
   
    {
        Header: 'Order Date',
        accessor: 'today',
        Filter: ColumnFilter,
        Cell: props => <Moment format="MM/DD/YYYY">{props.value}</Moment>,
        Flex:.8  
    },
]
export const rentalColumns = [
    {
        Header: 'Customer',
        accessor: 'customer.name',
        Filter: ColumnFilter,
        Flex:1.5 
    },
    {
        Header: 'Order Id',
        accessor: 'leaseid',
        Filter: ColumnFilter,
        Flex:.5  
    },
    {
        Header: "Items",
        accessor: "leases[]",
        Filter: ColumnFilter,
        Flex:3,
        Cell: ({ row }) => {
            return (
                 row.original.items
                    .map((item) => (
                        <div key={item._id}>
                            <>{item.productName}</>
                        </div>
                    ))
            );
        },
        disableFilters:true
    },
    {
        Header: "Term",
        accessor: "leases",
        Filter: ColumnFilter,
        Flex:.8,
        Cell: ({ row }) => {
            return (
                 row.original.items
                    .map((item,index) => (
                        <div key={index}>
                            <>{currency(item.leaseterms)}</>
                        </div>
                    ))
            );
        },
        disableFilters:true
    },
   
    {
        Header: 'Order Date',
        accessor: 'today',
        Filter: ColumnFilter,
        Cell: props => <Moment format="MM/DD/YYYY">{props.value}</Moment>,
        Flex:.8  
    },
]