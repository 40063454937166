import React from 'react'
import styled from 'styled-components'
import { Button, Flex, Img } from '../../shared';
import LOGO from '../../assets/mailpost2.png'


const Head = styled.div`
position:fixed;
height:5%;
width:100%;
left:0;
top:0;
display:flex;
 background-color:white;
 z-index:10;
`;
const Header = () => {
  return (
    <Head >
      <Flex padding="7px 0px 3px 0px" textAlign="left"  alignItems="flex-end" display="flex"><Img  height="100%"src={LOGO} alt="" /></Flex>
      <Flex height="100%"  justifyContent="flex-end" alignItems="flex-end" display="flex"><div><Button >Pam Brouder</Button></div></Flex>
    </Head>
  )
}

export default Header