import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Flexed, Wrap } from "../../shared";
import styled from 'styled-components'


export const Nav = styled.div`
width:100%;
height:3%;
color:white;
top:5%;
left:0;
position:fixed;
background-color:#7ABB43;
display:flex;
padding:10px 5vw;
justify-content:flex-start;
align-items:center;
text-align:left;
gap:20px;
z-index:10;


`;


const Navbar = () => {
  const options = [
    'Admin', 'two', 'three'
  ];


  const defaultOption = options[0];


    const [notopen, setNotopen] = useState(false)

    const [notifications, setNotifications] = useState([])
    const nav = useNavigate()
    
      /*  useEffect(() => {
    socket.on("getNotification",data => {
        setNotifications(prev =>[...prev,data])
    })
        },[notopen])
    */
    const userInfo ={name:"Test User"}


  return (

 <Nav>

  <Flex flex="0" textAlign="left" onClick={() => nav('/')}>Dashboard</Flex>
    <Flex flex="0" textAlign="left" onClick={() => nav('/customers')}>Customers</Flex>
    <Flex flex="0" textAlign="left" onClick={() => nav('/products')}>Products</Flex>
    <Flex flex="0" textAlign="left" onClick={() => nav('/schedule')}>Schedule</Flex>
 </Nav>         

  )
}

export default Navbar