import {useState,useEffect} from 'react'
import { Route, Routes } from 'react-router-dom'
import { Wrap, Flexed, Flex, Teir } from '../../shared'
import styled from 'styled-components'
import Header from './Header';
import Navbar from './Navbar';
import Sidemenu from './Sidemenu';
import Main from '../dash/Main';
import Customer from '../customers/Customer';
import Bookingsheet from '../customers/Bookingsheet';
import Leases from '../customers/Leases';
import Customers from '../customers/Customers';
import Supplies from '../customers/Supplies';
import Rentals from '../customers/Rentals';
import NewPurchase from '../customers/NewPurchase';
import Products from '../products/Products';
import axios from 'axios';
import NewLease from '../customers/NewLease';
import EditLease from '../customers/EditLease';
import Calender from './Calander/Calender';
import Schedules from './Schedules/Schedules';



const Content = styled.div`
width:calc(100% - 14.6%);
background-color:33EDEEF2;
margin-left:14.6%;
padding:0px 10px 10px 10px;
`;


const Dashboard = () => {
  const [allsales, setAllsales] = useState({})
  const [allleasesales, allLeasesales] = useState({})
  const [reset, setReset] = useState(false)


  useEffect(() => {
    const getToday = async() => {
      await axios.get(`https://mailpostapi.herokuapp.com/supplies/allsales`)
      .then(res => {setAllsales(res.data)})
      .catch(err => {
          console.log(err)
      })
    } 
    const getLeases = async() => {
      await axios.get(`https://mailpostapi.herokuapp.com/leases/allleasesales`)
      // await axios.get(`https://mailpostapi.herokuapp.com/leases/allleasesales`)
      .then(res => {allLeasesales(res.data)})
      .catch(err => {
          console.log(err)
      })
    } 
    

   
    getToday()  
    getLeases()
        },[reset]) 
console.log(allleasesales)

  return (
    <Teir padding="10px">
    <Wrap width="100%" position="relative" margin="0px auto">
       <Header />
       <Navbar />
       <Sidemenu allsales={allsales}  allleasesales={allleasesales}/>
       <Content>
        <Routes>
            <Route path="" element={<Main />} />
            <Route path="/customer" element={<Customer />} />
            <Route path="/products" element={<Products />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customer/newlease/:id" element={<NewLease />} />
            <Route path="/schedule" element={<Schedules />} />
            <Route path="/customer/leases/:id" element={<EditLease setReset={setReset} reset={reset}/>} />
            <Route path="/customer/supplies/:id" element={<Supplies setReset={setReset} reset={reset}/>} />
            <Route path="/customer/purchase/:id" element={<NewPurchase setReset={setReset} reset={reset}/>} />
        </Routes>
       </Content>
   </Wrap>
   </Teir>
  )
}

export default Dashboard