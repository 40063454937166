import {useState} from 'react'
import { Flexed, Input, Flex,Label, Textarea, Button} from '../../shared'
import axios from 'axios'
import States from '../components/States'

const EditLocation = (props) => {
console.log(props)
    const [locations,setLocations] = useState({})
const saveall = (e) => {
    let info = {'locations':locations,'id':props.id}
    e.preventDefault()
axios.post('https://mailpostapi.herokuapp.com/customers/add/location',info)
//axios.post('http://localhost:5004/customers/add/location',info)
.then(res => {console.log(res);props.setRecalc(!props.recalc);props.onCancel()})
.catch(err => console.log(err))
}

  return (
    <form onSubmit={saveall}>
    <Flexed gap="10px" padding="10px 0px">{props.index}
        <Flex textAlign="left"><Label>Location Name:</Label><br /><Input value={locations.locationname} onChange={e => setLocations({...locations,locationname:e.target.value})} required/> </Flex>
        <Flex textAlign="left"><Label>Address 1</Label><br /><Input value={locations.address1} onChange={e => setLocations({...locations,address1:e.target.value})} required/> </Flex>
        <Flex textAlign="left"><Label>Address 2</Label><br /><Input value={locations.address2} onChange={e => setLocations({...locations,address2:e.target.value})} /> </Flex>
    </Flexed>

    <Flexed gap="10px" padding="10px 0px" >
         <Flex textAlign="left"><Label>City</Label><br /><Input selected="Florida" value={locations.city} onChange={e => setLocations({...locations,city:e.target.value})} required/> </Flex>
    <Flex textAlign="left"><Label>State</Label><br /><States value={locations.state} onChange={e => setLocations({...locations,state:e.target.value})} required/> </Flex>
    <Flex textAlign="left"><Label>Zip</Label><br /><Input value={locations.zip} onChange={e => setLocations({...locations,zip:e.target.value})} required/> </Flex>
    <Flex textAlign="left"><Label>Attn:</Label><br /><Input value={locations.attn} onChange={e => setLocations({...locations,attn:e.target.value})} /> </Flex>
    <Flex textAlign="left"><Label>Phone:</Label><br /><Input value={locations.phone} onChange={e => setLocations({...locations,phone:e.target.value})} /> </Flex>
    </Flexed>
    <Flexed gap="10px" padding="10px 0px"><Flex textAlign="left"><Label>Notes:</Label><br /><Textarea value={locations.notes} onChange={e => setLocations({...locations,notes:e.target.value})}></Textarea>
    </Flex></Flexed>
    <Flexed justifyContent="flex-end" padding="20px 0px"><Button type="submit">Save Location</Button></Flexed>
    </form>
  )
}

export default EditLocation