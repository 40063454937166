import React, { useRef,useEffect ,useState} from "react";
import {currency,Flexed,Flex} from '../../../shared'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import LOGO from '../../../assets/mailpost2.png'
import ReactToPrint from "react-to-print";
import axios from "axios";


export default function SuppliesComponent({info,total}){
  let componentRef = useRef();

const [message, setMessage] = useState("")
const [leaseinfo, setLeaseinfo] = useState({})





  return (
    <>
      <div id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <LocalPrintshopOutlinedIcon style={{fontSize:"calc(1.5rem + .5vw)"}}/>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
        <ComponentToPrint  info={info} total={total}  ref={(el) => (componentRef = el)} />
        </div>
      </div>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    console.log(this.props)
   
  }


  render() {
    return (
      <div style={{border:"1px solid red",minHeight:"100vh",position:"relative",padding:"40px 40px 0px 20px"}}>
        {this.props.closedd && <div style={{width:"100%",height:"100%",backgroundColor:"rgb(255,255,255,.0)",position:"absolute",top:"0",left:"0",zIndex:"1",color:"rgb(0,0,0,.1)",display:"flex",justifyContent:"center",alignItems:"center"}}>
  <div style={{transform:"rotate(-45deg)",fontSize:"calc(4rem + .5vw)",letterSpacing:"50px"}}>CLOSED</div>
  </div>}
        <div style={{display:"flex"}}>
            <div style={{flex:"1"}}><img src={LOGO} alt="" style={{width:"100%"}}/></div>
            <div style={{flex:"1",fontSize:".8rem",paddingLeft:"30px"}}>{}
            4250 118th Ave N<br />Clearwater,Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.mailpostsystems.com
            </div>
            <div style={{flex:"1.9",fontSize:"2rem", fontWeight:"bold",textAlign:"center",marginTop:"-8px",padding:"0"}}>Supplies Order<br />{this.props.info?.supplyorderid}</div>
        </div>
        
        <div style={{display:"flex",marginTop:"20px"}}>
            <div style={{flex:"1.3",fontSize:".9rem", fontWeight:"bold"}}>Bill To:<span style={{fontSize:".9rem", fontWeight:"normal"}}><br />{this.props.info?.customer?.name} <br />{this.props.info?.billingaddress}</span></div>
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold"}}>Ship To <br /><br /><span style={{fontSize:".9rem", fontWeight:"normal"}}>{this.props.info?.shipingaddress}</span></div>
      
    
          
        </div>
     
        <div style={{display:"flex",marginTop:"30px"}}>
            <div style={{flex:"1.3",fontSize:".9rem", fontWeight:"bold"}}></div>
            <div style={{flex:"1.3",fontSize:".9rem", fontWeight:"bold"}}></div>
      
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold"}}>Order Date<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>12/20/2022</span><br /> </div>
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold"}}> Due Date<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>12/20/2022</span></div>
        </div>

<div style={{height:"61.5vh",border:"1px solid black",marginTop:"20px",position:"relative"}}>

<div style={{display:"flex",color:"black",boxSizing:"border-box",position:"relative",background:"rgb(0,0,0,.1)",borderBottom:"1px solid black",fontSize:".9rem"}}>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Part #</div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Quantity</div>


<div style={{flex:"2",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",borderRight:"1px solid black"}}>Item</div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Unit Cost </div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Unit Sell</div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Margin</div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Total Cost</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>Total Sell</div>
</div>

<div style={{display:"flex",color:"black",boxSizing:"border-box",height:"58vh",position:"relative"}}>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>


<div style={{flex:"2",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",borderRight:"1px solid black"}}></div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}></div>


</div>
<div style={{width:"100%",position:"absolute",top:"30px",left:"0",height:"60vh",background:"transparent"}}>
{

this.props.info?.items?.map(item => {

 return (
    <>
   
  <div key={item.id} style={{borderBottom:"1px solid red",display:"flex",color:"black",boxSizing:"border-box",fontSize:".7rem",alignItems:"flex-start"}}>
  <div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{item.sku}</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{item.quantity}</div>


<div style={{flex:"2",textAlign:"left",padding:"7px 5px"}}>{item.productName}</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{currency(item.cost)}</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{currency(item.price)} </div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{currency(item.margin)}</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{currency(item.extendecost)}</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>{currency(item.extendedtotal)}</div>
  </div>
  </>   
)

  } )



}

</div>


</div>
<div  style={{display:"flex",color:"black",boxSizing:"border-box",fontSize:".7rem",alignItems:"flex-start"}}>
  <div style={{flex:"4.3",textAlign:"left",height:"100%",padding:"7px 5px"}}>Labor: {currency(this.props.info.laborcost *  this.props.info.laborhours|| 0)} &nbsp;&nbsp;Freight: {currency(parseFloat(this.props.info.freightcharge)) || 0} </div>
  <div style={{flex:"1",textAlign:"right",padding:"7px 5px"}}>Total:&nbsp;</div>
  <div style={{flex:".5",textAlign:"right",padding:"7px 5px"}}>{currency(this.props.total)}</div>
 
  </div>
      </div>
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////



 
