
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea,Datepick } from '../../../shared'
import { PortalWrapper,PortalInner} from './CalendarStyles'

const Portal = ({setSelectedjob,selectedjob,setShowportal,newlist,setNewlist}) => {

    const resetEvent = () => {

  
    }



    console.log(selectedjob)
  return (
    <PortalWrapper>
        <PortalInner>
        <Flexed padding="10px 0px">
            <Flex textAlign="left"><Label><p><h2>{selectedjob.customer}</h2></p></Label></Flex>
            <Flex textAlign="right"><Label><p><h2>{selectedjob.job}</h2></p></Label></Flex>
        </Flexed>
        <Flexed gap="20px">
        <Flex textAlign="left"><Label>Status</Label><br />
        <Selectbox value={selectedjob.status} onChange={e => setSelectedjob({...selectedjob,status:e.target.value})}>
        <option>Select Status</option>
        <option value="2" style={{background:"#70AD47"}}>Ready Press</option>
            <option value="3" style={{background:"green"}}>On Press</option>
            <option value="4" style={{background:"#ED7D31"}}>Off Press</option>
            </Selectbox> 
        </Flex>
        <Flex textAlign="left"><Label>Press</Label><br />
        <Selectbox>
            <option>Anicolor</option>
            <option>GL 40</option>
            <option>M110</option>
            </Selectbox> 
        </Flex>
        <Flex><Label>Date</Label><br />
        <Datepick selected={new Date(selectedjob.date)} onChange={(date) => setSelectedjob({...selectedjob,date:date})} />
        </Flex>
        
        </Flexed>
        <Flexed gap="40px" padding="10px 0px">
        <Flex textAlign="left" style={{display:"inline"}}  >
    <Input type="checkbox" height="15px" width="15px"/> <br />Press Check 
        </Flex> 
       
        <Flex textAlign="left" style={{display:"inline",alignItems:"center",padding:"10px 0px",justifyContent:"flex-end"}}  >
    <Input type="checkbox" height="15px" width="15px"/> <br />Job On Hold
        </Flex>
        <Flex textAlign="left" style={{display:"inline",alignItems:"center",padding:"10px 0px",justifyContent:"flex-end"}}  >
    <Input type="number"  value={selectedjob.priority} onChange={e => setSelectedjob({...selectedjob,priority:e.target.value})}/>  <br />Priority
        </Flex> 
        <Flex flex="2" textAlign="left">
            <Label>Notes</Label><br />
            <Textarea rows="4" cols="40"></Textarea>
        </Flex>
        </Flexed>
        <Flexed padding="20px 0px 0px 0px">
        <Flex textAlign="left"><Button onClick={() => setShowportal(false)}>Cancel</Button></Flex>
        <Flex textAlign="right"> <Button onClick={() => {setNewlist(!newlist);setShowportal(false)}}>Save</Button></Flex>
        </Flexed>
        
        </PortalInner>
      
        
        
        </PortalWrapper>
  )
}

export default Portal