import { useEffect, useRef, useState } from 'react'
import {SchedulerWrapper,CalenderHead,SevenCol,HeadDay,CalenderBody,StyledDay, StyledEvent, CalNav,CalWrap,CalList} from './CalendarStyles'
import AddEvent from './AddEvent';
import Modal from '../../../modals/Modal';
import Portal from './Portal';
import {MONTHS, WEEKDAYS } from '../../../utils/Utilities'


const getDaysInMonth = (month,year) => {
  return new Date(year, month + 1,0).getDate()
  }

  const range = (end) => {
    const {result} = Array.from({length:end}).reduce(({result,current}) => ({
  result: [...result,current],
  current: current + 1
    }),
    {result: [], current: 1}
    );
  return result
  }


  const getSortedDays = (month,year) => {

const dayIndex = new Date(year, month,1).getDay();
return [...WEEKDAYS.slice(dayIndex), ...WEEKDAYS.slice(0, dayIndex)];
  }

const getDateObj = (day, month,year) => {
return new Date(year, month, day)
}

const areDatesTheSame = (first, second) => {
return first?.getFullYear() === second?.getFullYear() &&
 first?.getMonth() === second?.getMonth() && 
 first?.getDate() === second?.getDate()
}

 const MOCKEVENTS =  [
    {date: new Date(2023,4,18), customer:"Creative Pinellas",job:123456,hours:5,id:"6",status:1,form:"1) Cover"},
    {date: new Date(2023,4,12), customer:"Creative Pinellas",job:154254,hours:8,id:"7",status:0,form:"2) Form 08pg"},
    {date:new Date(2023,4,12), customer:"Creative Pinellas",job:695874,hours:.5,id:"8",status:0,form:"Form 08pg Rollout"},
    {date:new Date(2023,4,12), customer:"Creative Pinellas",job:362563,hours:5.3,id:"9",status:0,form:"3) Form 16pg"},
    {date: new Date(2023,4,17), customer:"Creative Pinellas",job:123456,hours:5,id:"10",status:0,form:"4) Form 16pg"},
    {date: new Date(2023,4,19), customer:"Company D",job:154254,hours:8,id:"11",status:0,form:"Cover"},
    {date:new Date(2023,4,23), customer:"Company D",job:695874,hours:.5,id:"12",status:0,form:"Text"},
    {date:new Date(2023,4,23), customer:"Company F",job:362563,hours:5.3,id:"13",status:0,form:"04pg"},
    {date: new Date(2023,4,24), customer:"Other",job:123456,hours:5,id:"14",status:0,form:"08pg"},
  
  
]



const COMINGVENTS =  [
    {date: null, customer:"Better Homes & Gardens",job:100256,hours:2.5,id:"1",status:0,form:"Cover"},
    {date: null, customer:"DMS",job:100584,hours:4,id:"2",status:0,form:""},
    {date: null, customer:"Royal Carribean",job:100258,hours:12,id:"3",status:0,form:""},
    {date: null, customer:"Snapple's",job:100298,hours:32,id:"4",status:0,form:""},
  
]





const M110 = () => {
const startingDate = new Date()
    const newDate = new Date()
    const year = new Date().getFullYear()
    const day = new Date().getDay()
    const month = new Date().getMonth() + 1
    let firstDay = (new Date(year, month)).getDay();
    const lastDay = new Date(year, month, 0).getDate();

const [currentMonth, setCurrentMonth] = useState(startingDate.getMonth()) 
const [currentYear, setCurrentYear] = useState(startingDate.getFullYear())
const [addeventform, setAddeventform] = useState(false) 
const [showportal, setShowportal] = useState(false) 
const [recalc, setRecalc] = useState(true) 
const daysInMonth = getDaysInMonth(currentMonth,currentYear)
const [events, setEvents] = useState() 
const [notevents, setNotevents] = useState() 
const [selectedjob, setSelectedjob] = useState({})
const [newlist, setNewlist] = useState(false)
const [trackid, setTrackid] = useState()
const draggedElREf = useRef()
const draggedElIdRef = useRef()


useEffect(() => {
  setEvents(MOCKEVENTS)
  setNotevents(COMINGVENTS)
},[])


useEffect(() => {
  const r = events?.filter(item => item.id === selectedjob.id)
  console.log(r)



if(r !== undefined ){
  let index = events.findIndex( x => x.id === selectedjob.id);
const newTodos = [...events];
newTodos[index] = selectedjob;
setEvents(newTodos);


}

},[newlist])

const onDragStart = (id) => {
  draggedElIdRef.current = id
}

const onDragEnter = (e,date) => {
e.preventDefault()
draggedElREf.current = date

}

const onDragEvents = (updatedEvents) => {
  setEvents(updatedEvents)
  //setNotevents(updatedEvents)
  }


const onDragEnd = (e) => {
  e.preventDefault()


  const updatedEvents = events.map((event) => {
    if(event.id === draggedElIdRef.current){
    event.date = draggedElREf.current
    event.status = 0
    }
    return event
    })

  const isFound = events.some(element => {
    if (element.id === draggedElIdRef.current) {
      return true;
    }

    return false;
  });
  



 if(isFound){
  onDragEvents(updatedEvents)
 }else{
const r = notevents.filter(item => item.id === draggedElIdRef.current)

const y = {
  date: draggedElREf.current,
  customer:r[0].customer,
  job:r[0].job,
  hours:r[0].hours,
  id:r[0].id,
  status:r[0].status,
  form:r[0].form  
}
console.log(r)
setEvents(events => [...events, y])

const t = notevents.filter(item => item.id !== draggedElIdRef.current)
setNotevents(t)
 }

}

const bgChange = (item) => {
  let colors = {}
switch(item){
case 1 :
  colors.bgcolor="green"
  colors.color= "pink"

}
return colors
}

console.log(events)




const nextMonth = () => {
  if(currentMonth < 11){
setCurrentMonth(prev => prev + 1)
  }else{
    setCurrentMonth(0)
    setCurrentYear(prev => prev + 1)
  }
}

const prevMonth = () => {
  if(currentMonth > 0){
setCurrentMonth(prev => prev - 1)
  }else{
    setCurrentMonth(11)
    setCurrentYear(prev => prev - 1)
  }
}

const handleEvent = (event) => {
  setSelectedjob(event)
setShowportal(true);

}



  return (
    <>
         
    <SchedulerWrapper>
    {showportal && <Portal setSelectedjob={setSelectedjob} selectedjob={selectedjob} setShowportal={setShowportal} setNewlist={setNewlist} newlist={newlist}/>}
     
      <Modal show={addeventform} header={`Add New Job To Schedule`} onCancel={() => {setAddeventform(false)}} ><AddEvent onCancel={() => {setAddeventform(false)}}  setRecalc={setRecalc} recalc={recalc} events={events} setEvents={setEvents}/></Modal>

<CalList>
{notevents?.map(
  (ev) => {return <StyledEvent  bgColor="var(--plsBlue)" onClick={() => handleEvent(ev)} onDragStart={() => onDragStart(ev.id)} draggable ><div style={{padding:"3px"}}>{ev.customer}</div> <div style={{width:"100%",color:"black",background:"lightgrey",padding:"3px"}}>{ev.job}{ev.hours &&  ' - '+ev.hours+'/hrs'}<br />{ev.form}</div></StyledEvent>
})}
</CalList>
      <CalWrap>
      <CalenderHead>
        <CalNav>
      <ion-icon onClick={prevMonth} name="arrow-back-outline"></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {MONTHS[currentMonth]} {currentYear} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <ion-icon onClick={nextMonth} name="arrow-forward-outline"></ion-icon>
      </CalNav>
      <div style={{flex:"1",textAlign:"right"}}>
      <button onClick={e => setAddeventform(true)}>Add</button>
      </div>
      </CalenderHead>
      <SevenCol>
        {getSortedDays(currentMonth,currentYear).map((day,index) => ( <HeadDay key={index} >{day}</HeadDay>))}
           </SevenCol>
       <CalenderBody forthCol={daysInMonth === 28}>
{range(daysInMonth)?.map(day => (
<StyledDay 
onDragEnter={e => onDragEnter(e,getDateObj(day,currentMonth, currentYear))}
onDrop={onDragEnd}
onDragOver={e => {e.preventDefault()}}
  active={areDatesTheSame(
    new Date(),
    getDateObj(day, currentMonth, currentYear)
    )}>
  <p>{day}</p>

{events?.map(
  (ev) =>
  areDatesTheSame(
    getDateObj(day,currentMonth,currentYear),
    ev.date
  ) && <StyledEvent color={bgChange(ev.status).color} bgColor={bgChange(ev.status).bgcolor} status={ev.status === 1} onClick={() => handleEvent(ev)} onDragStart={() => onDragStart(ev.id)} draggable ><div style={{padding:"2px"}}>{ev.customer}</div>
  <div style={{width:"100%",color:"black",background:"lightgrey",padding:"2px"}}>{ev.job}{ev.hours &&  ' - '+ev.hours+'/hrs'}<br />{ev.form}</div></StyledEvent>
    )}
</StyledDay>
))}
       </CalenderBody>
       </CalWrap>
    </SchedulerWrapper>
    </>
  )
}

export default M110





//        <div style={{}}></div>