import {useEffect, useState} from 'react'
import { Button, Flex, Flexed, Img, Label, PhoneNumber, Scroll, Teir, Textarea, Wrap,currency } from '../../shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { Link,useSearchParams,useNavigate  } from 'react-router-dom'
import axios from 'axios';
import Moment from 'react-moment';
import AddLocation from './AddLocation';
import Modal from '../../modals/Modal';
import AddContact from './AddContact';
import EditLocation from './EditLocation.js';
import {AiFillEdit} from 'react-icons/ai'









const Customer = () => {
    const [queryParameters] = useSearchParams()
    const id = queryParameters.get('id')
   
const [recalc , setRecalc] = useState(true)
const [customer, setCustomer] = useState({})
const [mainlocation, setMainlocation] = useState({})
const [moreinfo, setMoreinfo] = useState(false)
const [receiveform, setReceiveform] = useState(false)
const [reload, setReload] = useState(false)
const [leases, setLeases] = useState([])
const [rentals, setRentals] = useState([])
const [purchases, setPurchases] = useState([])
const [locationform, setLocationform] = useState(false)
const [allpurchases, setAllpurchases] = useState([]);
const [allleases, setAllleases] = useState([]);
const [addcontactform, setAddcontactform] = useState(false);
const [editlocationform, setEditlocationform] = useState(false);
const [index, setIndex] = useState(false);





const navigate= useNavigate();
useEffect(() => {

const getCust = async() => {
    const t = await axios.get(`https://mailpostapi.herokuapp.com/customers/${id}`)
    .then(res => {console.log(res.data);setCustomer(res.data);setMainlocation(res.data.address[0]);
    })
    .catch(err => {
        console.log(err)
        navigate('/customers')
    })
}

/*const getEmps = async() => {
    await axios.get(`http://localhost:5003/customers/${id}`)
    .then(res => {setCustomer(res.data)})
    //.then(res => {setMainlocation(res.data.address.filter(item => item.main === "1"))})
    .catch(err => {
        console.log(err)
    })
}*/
getCust()
//getEmps()

},[id,recalc])



useEffect(() => {
    const getdatastuff = async() => {
        let t = {"id":id}
   //   await axios.get(`https://mailpostapi.herokuapp.com/supplies/allSalesByCustomer,`,t)
   await axios.post(`https://mailpostapi.herokuapp.com/supplies/allSalesByCustomer`,t)
      .then(res => {console.log(res.data);setAllpurchases(res.data)})
      //.then(res => {setMainlocation(res.data.address.filter(item => item.main === "1"))})
      .catch(err => {
          console.log(err)
      })
    }
    
    const getleasestuff = async() => {
      await axios.get(`https://mailpostapi.herokuapp.com/leases/allleases`)
      .then(res => {console.log(res.data);setAllleases(res.data)})
      //.then(res => {setMainlocation(res.data.address.filter(item => item.main === "1"))})
      .catch(err => {
          console.log(err)
      })
    }
    
                        getdatastuff()    
                        getleasestuff()        
        },[]) 


useEffect(() => {
    let customr = {"id":id}
    console.log(customr)
    const getallcustomers =  async() => {
            //const t =  await axios.post('https://mailpostapi.herokuapp.com/leases/all',customr)
            const t =  await axios.post('https://mailpostapi.herokuapp.com/leases/all',customr)
              .then(res => {setLeases(res.data)})
              .catch(err => console.log(err)) 
                  
    }

    const getallsupplies =  async() => {
        let customr = {"id":id}
       // const t =  await axios.post(`https://mailpostapi.herokuapp.com/supplies/customer`,customr)
        const t =  await axios.post(`https://mailpostapi.herokuapp.com/supplies/customer`,customr)
          .then(res => {setPurchases(res.data)})
          .catch(err => console.log(err)) 
              
}

    getallcustomers()
    getallsupplies()
    },[reload])



console.log(purchases)

  return (
   <Teir >
<Modal show={locationform} header={`Add New Location`} onCancel={() => {setLocationform(false)}} ><AddLocation onCancel={() => {setLocationform(false)}}  setRecalc={setRecalc} recalc={recalc} id={id}/></Modal>
<Modal show={addcontactform} header={`Add New Contact`} onCancel={() => {setAddcontactform(false)}} ><AddContact onCancel={() => {setAddcontactform(false)}}  setRecalc={setRecalc} recalc={recalc} id={id}/></Modal>
<Modal show={editlocationform} header={`Edit Current Location`} onCancel={() => {setEditlocationform(false)}} ><EditLocation onCancel={() => {setEditlocationform(false)}}  setRecalc={setRecalc} recalc={recalc} id={id} index={index}/></Modal>
<Flexed fontSize="calc(.5rem + .5vw)" gap="10px" padding="20px 20px 0px 20px">
    <Flex textAlign="left" flex="2" alignItems="center"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">Customer #&nbsp;<span style={{color:"black",fontWeight:"normal"}}>{customer.company_id}</span></Label><br /><span style={{fontSize:"calc(1.6rem + .5vw)",fontWeight:"500"}}>{customer.name}</span><br /><div onClick={() => setReceiveform(true)} style={{display:"flex",alignItems:"center",color:"var(--plsGreen"}}>Edit Details</div></Flex>
    <Flex textAlign="left" flex="1.5"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">Main Address</Label><br />{mainlocation?.address1}{mainlocation?.address2 > "" ? <><br /> {mainlocation?.address2}</> : ''}<br />{mainlocation?.city}{mainlocation?.state > "" ? <>, {mainlocation?.state}</> : ''} {mainlocation?.zip > "" ? <>, {mainlocation?.zip}</> : ''}{mainlocation?.attn > "" ? <><br />Attn: {mainlocation?.attn}</> : ''}</Flex>
    <Flex textAlign="left" flex="1"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">Main Phone</Label><br />M: {PhoneNumber(customer.mainphone)}<br />F:{PhoneNumber(customer.mainphone)}</Flex>
    <Flex textAlign="left" flex=".8"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">Main Contact</Label><br /></Flex>
    <Flex textAlign="left" flex=".8"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">Salesperson</Label><br />{customer?.salesrep}<br /></Flex>
    <Flex textAlign="left" flex=".8"><Label color="var(--plsSecondBlue)" fontWeight="bold" fontSize="calc(.5rem + .5vw)">CSR</Label><br />{customer?.custrep}</Flex>
    <div style={{position:"absolute", bottom:"0",right:"25px",color:"var(--plsGreen"}} onClick={() => setMoreinfo(!moreinfo)}>{moreinfo ? 'Close Info' : 'More Info'}</div>
</Flexed>

   {moreinfo && <> <div style={{position:"absolute", top:"0",right:"0"}} onClick={() => setMoreinfo(!moreinfo)}></div>
   <Flexed padding="0px 10px" >
    <Flex textAlign="left" ></Flex>
    <Flex textAlign="left" ><Label><u>Tax Exempt</u></Label><br />{customer.taxexempt}<br /><br /><Label><u>Billing Terms</u></Label><br />{customer.billingterms}</Flex>

    <Flex textAlign="left" ><Label><u>Customer Since</u></Label><br />{customer.custsince}<br /><br /><Label><u>Website</u></Label><br />{customer.website}</Flex>
    <Flex textAlign="left" ><Label>Notes</Label><br /><Textarea rows="5" value={customer.notes} disabled/></Flex>

</Flexed></>}
<div style={{padding:"20px"}}>
<Flexed bgColor="var(--plsBlue)" color="white">
    <Flex flex="1" >Today</Flex>
    <Flex flex="1" >This Week</Flex>
    <Flex flex="1" >Last Week</Flex>
    <Flex flex="1" >This Month</Flex>
    <Flex flex="1" >QTD</Flex>
    <Flex flex="1" >YTD</Flex>
</Flexed>
<Flexed bgColor="white">

    <Flex flex="1" >{isNaN(allpurchases.today) ? currency(0.00) : currency(allpurchases.today)}</Flex>
    <Flex flex="1" >{isNaN(allpurchases.week) ? currency(0.00) : currency(allpurchases.week)}</Flex>
    <Flex flex="1" >{isNaN(allpurchases.lastweek) ? currency(0.00) : currency(allpurchases.lastweek)}</Flex>
    <Flex flex="1" >{isNaN(allpurchases.month) ? currency(0.00) : currency(allpurchases.month)}</Flex>
    <Flex flex="1" >{isNaN(allpurchases.quarter) ? currency(0.00) : currency(allpurchases.quarter)}</Flex>
    <Flex flex="1" >{isNaN(allpurchases.year) ? currency(0.00) : currency(allpurchases.year)}</Flex>
</Flexed>

<Flexed bgColor="var(--plsBlue)" color="white" margin="50px 0px 0px 0px" alignItems="center">
    <Flex fontWeight="bold" textAlign="left" padding="0px 0px 0px 5px">CONTACTS&nbsp; &nbsp;{customer.contacts?.length}</Flex>
    <Flex textAlign="right" color="var(--plsBlue)" ><Button onClick={e => setAddcontactform(true)}>Add</Button></Flex>
</Flexed>
<Flexed bgColor="white" color="var(--plsSecondBlue)" gap="10px" padding="0px 0px 0px 5px">

    <Flex flex="1.5" textAlign="left" >Name</Flex>
    <Flex flex="1.5" textAlign="left" >Title</Flex>
    <Flex flex="1.5" textAlign="left" >Office Phone</Flex>
    <Flex flex="2"textAlign="left"  >Email</Flex>
    <Flex flex="1" textAlign="left" >Invoice</Flex>
    <Flex flex="1" textAlign="left" >Edit</Flex>

</Flexed>
<div style={{backgroundColor:"white"}}>

{customer.contacts?.map(person => {
  return <Flexed key={person._id} bgColor="white" fontSize="var(--smallText)" gap="10px" padding="5px 0px 0px 5px">
    <Flex flex="1.5" textAlign="left" >{person.name}</Flex>
    <Flex flex="1.5" textAlign="left" >{person.title}</Flex>
    <Flex flex="1.5" textAlign="left" >{person.officephone !== 0 ? PhoneNumber(person.officephone) : ''}</Flex>
    <Flex flex="2" textAlign="left" ><Link   to='#' onClick={(e) => {window.location = `mailto:${person.email}`;e.preventDefault();}}>{person.email}</Link></Flex>
    <Flex flex="1" textAlign="left" >{person.invoice}</Flex>
    <Flex flex="1" textAlign="left" ></Flex>

</Flexed>

})}

</div>
<br />
<Flexed bgColor="var(--plsBlue)" color="white" margin="10px 0px 0px 0px" alignItems="center">
    <Flex fontWeight="bold" textAlign="left" padding="0px 0px 0px 5px">Locations&nbsp; &nbsp;{customer.address?.length}</Flex>
    <Flex textAlign="right" color="var(--plsBlue)"><Button onClick={e => setLocationform(true)}>Add</Button></Flex>
</Flexed>
<Flexed  bgColor="white" color="var(--plsSecondBlue)" gap="10px" padding="0px 0px 0px 5px">
    <Flex flex="1.5" textAlign="left" >Facility Name</Flex>
    <Flex flex="1.5" textAlign="left" >Address</Flex>
    <Flex flex="1.5" textAlign="left" >City</Flex>
    <Flex flex="2"textAlign="left"  >State</Flex>
    <Flex flex="1" textAlign="left" >Zip</Flex>
    <Flex flex="1" textAlign="left" >Attn</Flex>
    <Flex flex="1" textAlign="left" ></Flex>
</Flexed>
<div style={{backgroundColor:"white"}}>

{customer.address?.map(person => {
  return  <Flexed  key={person._id} bgColor="white" fontSize="var(--smallText)" gap="10px" padding="5px 0px 0px 5px">
    <Flex flex="1.5" textAlign="left" >{person.locationname}</Flex>
    <Flex flex="1.5" textAlign="left" >{person.address1} {person.address2}</Flex>
    <Flex flex="1.5" textAlign="left" >{person.city}</Flex>
    <Flex flex="2" textAlign="left" >{person.state}</Flex>
    <Flex flex="1" textAlign="left" >{person.zip}</Flex>
    <Flex flex="1" textAlign="left" >{person.attn}</Flex>
    <Flex flex="1" textAlign="left" onClick={e => setEditlocationform(true)}><AiFillEdit /></Flex>
</Flexed>
})}

</div>
<br /><hr />


<Flexed bgColor="var(--plsGrey)" color="white" margin="30px 0px 0px 0px" alignItems="center">
    <Flex fontWeight="bold" textAlign="left" padding="0px 0px 0px 5px">Purchases - {purchases.length}</Flex>
    <Flex display="flex" justifyContent="flex-end"><Button color="var(--plsBlue)" onClick={() => navigate(`/customer/supplies/${id}`)}>New Purchase </Button></Flex>
</Flexed>
<Flexed bgColor="white" color="var(--plsSecondBlue)" gap="10px" padding="5px">
    <Flex flex="1" textAlign="left" >Quantity</Flex>
    <Flex flex="1" textAlign="left" >Ordered</Flex>
    <Flex flex="1" textAlign="left" >ID#</Flex>
    <Flex flex="1" textAlign="left" >SKU</Flex>
    <Flex flex="4" textAlign="left" >Product</Flex>
    <Flex flex=".8"textAlign="left"  >Price</Flex>
    <Flex flex=".8" textAlign="left" >Cost</Flex>
    <Flex flex=".8" textAlign="left" >Margin</Flex>
    <Flex flex="1" textAlign="left" >Total</Flex>
    <Flex flex=".8" textAlign="left" >Status</Flex>
</Flexed>
<div style={{backgroundColor:"white",maxHeight:"300px",overflowY:"scroll"}} >

{purchases.map(item => (
    item.items?.map(product => {
    
        return  <Flexed  className="navigate" key={product._id} bgColor="white" fontSize="var(--smallText)" gap="10px" padding="5px" onClick={e => navigate(`/customer/purchase/${item._id}`)}>
               <Flex flex="1" textAlign="left" >{product.quantity}</Flex>
        <Flex flex="1" textAlign="left" ><Moment format="MM/DD/YYYY">{product.orderdate}</Moment></Flex>
        <Flex flex="1" textAlign="left" >{item.supplyorderid}</Flex>
        <Flex flex="1" textAlign="left" >{product.product.sku}</Flex>
        <Flex flex="4" textAlign="left" >{product.product.description}</Flex>
        <Flex flex=".8" textAlign="left" >{product.price}</Flex>
        <Flex flex=".8" textAlign="left" >{product.cost}</Flex>
        <Flex flex=".8" textAlign="left" >{product.margin}</Flex>
        <Flex flex="1" textAlign="left" >{currency(product.extendedtotal)}</Flex>
        <Flex flex=".8" textAlign="left" >Open</Flex>
    </Flexed>
    })
  
)).reverse()}

</div>
<br />
<Flexed bgColor="var(--plsGrey)" color="white" margin="30px 0px 0px 0px" alignItems="center">
    <Flex fontWeight="bold" textAlign="left" padding="2px 0px 2px 5px">Rentals - {rentals.length}</Flex>
    <Flex display="flex" justifyContent="flex-end"><Button color="var(--plsBlue)" onClick={() => navigate(`/customer/bookingsheet/${id}`)}>New Rental</Button></Flex>
</Flexed>


<Flexed bgColor="white" color="var(--plsSecondBlue)" gap="10px" padding="5px 0px 5px 5px">
<Flex flex="1" textAlign="left" >Rental ID </Flex>
<Flex flex="1" textAlign="left" >Sku</Flex>
    <Flex flex="3" textAlign="left" >Item</Flex>
    <Flex flex="1.5" textAlign="left" >Quantity</Flex>
    <Flex flex="1.5" textAlign="left" >Maint</Flex>
    <Flex flex="2"textAlign="left"  >Purchased</Flex>

    <Flex flex="1" textAlign="left" >Financed</Flex>
  
</Flexed>

<div style={{backgroundColor:"white"}}>

 {rentals.map(item =>  ( 

    item.items?.map(product =>   (
        <Flexed key={product._id} bgColor="white" fontSize="var(--smallText)" gap="10px" padding="5px 0px 0px 5px">
               <Flex flex="1" textAlign="left" >{item.leaseid}</Flex>
                    <Flex flex="1" textAlign="left" >{product.sku}</Flex>
        <Flex flex="3" textAlign="left" >{product.productName}</Flex>
        <Flex flex="1.5" textAlign="left" >{product.maintterms} Months</Flex>
        <Flex flex="1.5" textAlign="left" >{product.leaseterms} Months</Flex>
        <Flex flex="2" textAlign="left" >{product.extendedtotal}</Flex>

        <Flex flex="1" textAlign="left" >{product.leaseamount}</Flex>
     
    </Flexed>
    ))
))}



</div>
<br />
<Flexed bgColor="var(--plsGrey)" color="white" margin="30px 0px 0px 0px" alignItems="center">
    <Flex fontWeight="bold" textAlign="left" padding="2px 0px 2px 5px">Leases - {leases.length}</Flex>
    <Flex display="flex" justifyContent="flex-end"><Button color="var(--plsBlue)" onClick={() => navigate(`/customer/newlease/${id}`)}>New Lease</Button></Flex>
</Flexed>


<Flexed bgColor="white" color="var(--plsSecondBlue)" gap="10px" padding="5px 0px 5px 5px">
<Flex flex="1" textAlign="left" >Lease ID </Flex>
<Flex flex="1" textAlign="left" >Sku</Flex>
    <Flex flex="3" textAlign="left" >Item</Flex>
    <Flex flex="1.5" textAlign="left" >Terms</Flex>
    <Flex flex="1.5" textAlign="left" ></Flex>
    <Flex flex="2"textAlign="left"  >Purchased</Flex>

    <Flex flex="1" textAlign="left" >Financed</Flex>
  
</Flexed>

<div style={{backgroundColor:"white"}}>

 {leases?.map(item =>  ( 

    item.items?.map(product =>   (
        <Flexed key={product._id}  onClick={() => navigate(`/customer/leases?id=${item._id}&type=edit`)} bgColor="white" fontSize="var(--smallText)" gap="10px" padding="5px 0px 0px 5px">
               <Flex flex="1" textAlign="left" >{item.leaseid}</Flex>
                    <Flex flex="1" textAlign="left" >{product.sku}</Flex>
        <Flex flex="3" textAlign="left" >{product.productName}</Flex>
        <Flex flex="1.5" textAlign="left" >{item.leaseterms} </Flex>
        <Flex flex="1.5" textAlign="left" >{product.leaseterms}</Flex>
        <Flex flex="2" textAlign="left" >{product.extendedtotal}</Flex>

        <Flex flex="1" textAlign="left" >{item.leaseamount}</Flex>
     
    </Flexed>
    ))
    

))}



</div>
</div>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

   

   </Teir>
  )
}

export default Customer



/*
 


*/