import logo from './logo.svg';
import './App.css';
import Dashboard from './pages/components/Dashboard';

function App() {
  return (
    <div className="App">
        <Dashboard />
        {/*userInfo.logged ? <Dashboard /> : <Login/>*/}
    </div>
  );
}

export default App;
