import axios from 'axios'
import { useState, useEffect} from 'react'
import { Flex, Flexed, Teir } from '../../shared'
import { customerColumns } from '../components/Columns'
import Table from '../components/Table'
import Modal from '../../modals/Modal'
import AddCustomer from './AddCustomer'
import { BsPersonFillAdd } from 'react-icons/bs'



const Customers = () => {

    const [DATA, setDATA] = useState([])
    const [list, setList] = useState('')
    const [customerform, setCustomerform] = useState(false)
    const [recalc, setRecalc] = useState(false)


useEffect(() => {

 const getall = async() => {
await axios.get('https://mailpostapi.herokuapp.com/customers/all')
.then(res => {console.log(res);setDATA(res.data)})
.catch(err => console.log(err))
 }

 getall()
},[recalc])

  return (
    <Teir>
<Flexed>
<Modal show={customerform} header={`Add New Customer`} onCancel={() => {setCustomerform(false)}} ><AddCustomer onCancel={() => {setCustomerform(false)}}  setRecalc={setRecalc} recalc={recalc}/></Modal>
  <Flex fontSize="calc(1.2rem + .5vw)" padding="10px 0px">MPS Customer LIst</Flex>
  <div ><BsPersonFillAdd style={{color:"var(--plsGreen)",fontSize:"calc(1.8rem + .5vw)",position:"relative",top:"60px",zIndex:"5"}} onClick={() => setCustomerform(true)} /></div>
</Flexed>
<Table supplyColumns={customerColumns} DATA={DATA} setter="customer"/>
    </Teir>
  )
}

export default Customers