import axios from 'axios'
import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const AddCustomer = ({onCancel,recalc,setRecalc}) => {


const [customer, setCustomer] = useState({
   
    name:"",
    dba:"",
    salesrep:"",
    custrep:"",
  address:{
    address1:"",
    address2:"",
    city:"",
    state:"",
    zip:"",
    main:"",
    attn:"",
    locationname:"",
    notes:"",
    mainphone:""
  },
  mainphone:"",
    website:"",
    department:"PLS",
    taxexempt:"No",
    billingterms:"Net 30",
    permits:"",
    custsince:null,
    notes:""
})
const [isChecked, setIsChecked] = useState(true);

const handleOnChange = () => {
    setIsChecked(!isChecked);
  };



const submit = (e) => {
 e.preventDefault()
 
 customer.iscustomer = isChecked

 if(isChecked){
    customer.custsince = new Date().toISOString();
 }

 if(customer.address.address1 > ""){
customer.address.main = 1;
 }
customer.custrep="Julie A"
customer.salesrep="Don B"

 console.log(customer)
axios.post('https://mailpostapi.herokuapp.com/customers/add',customer)
.then(res => {setRecalc(!recalc);onCancel()})
.catch(err => toast.error(`${err.response.err.message}`))
 
}

  return (
    <Wrap>
        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">  <Label>Company Name</Label><br /><Input type="text" placeholder="Customer Name" value={customer.name} onChange={e => setCustomer({...customer,name:e.target.value})} required/></Flex>
<Flex textAlign="left"  flex="1.5">  <Label>DBA</Label><br /><Input type="text" placeholder="Customer DBA" value={customer.dba} onChange={e => setCustomer({...customer,dba:e.target.value})}/></Flex>
<Flex textAlign="left"  flex="1">
<Label>Service Rep</Label><br />
    <Input value="Julie A" disabled={true} />
        </Flex>
<Flex textAlign="left"  flex="1">
    <Label>Sales Rep</Label><br />
    <Input value="Don B" disabled={true} />
</Flex>
    </Flexed >



    <Flexed gap="15px" padding="20px 0px 0px 0px" >
    <Flex flex="1" textAlign="left" ><Label>Main Location Name</Label><br /><Input type="text" placeholder="Main Location Name" value={customer.address?.locationname} onChange={e => setCustomer({...customer,address:{...customer.address,locationname:e.target.value}})}/></Flex>
    <Flex textAlign="left" ><Label>Address 1</Label><br /><Input type="text" placeholder="Address 1" value={customer.address?.address1} onChange={e => setCustomer({...customer,address:{...customer.address,address1:e.target.value}})}/></Flex>
        <Flex textAlign="left" ><Label>Address 2</Label><br /><Input type="text" placeholder="Address 2" value={customer.address?.address2} onChange={e => setCustomer({...customer,address:{...customer.address,address2:e.target.value}})}/></Flex>
        <Flex flex=".7" textAlign="left" ><Label>Attn</Label><br /><Input type="text" placeholder="Address 2" value={customer.address?.attn} onChange={e => setCustomer({...customer,address:{...customer.address,attn:e.target.value}})}/></Flex>

    </Flexed>

<Flexed gap="15px" padding="20px 0px 0px 0px" >
<Flex flex="1">
<Flexed gap="15px" >
       
        <Flex textAlign="left" flex="1.5"><Label>City</Label><br /><Input type="text" placeholder="Address 1" value={customer.address?.city} onChange={e => setCustomer({...customer,address:{...customer.address,city:e.target.value}})}/></Flex>
        <Flex textAlign="left" flex="1"><Label>State</Label><br /><Input type="text" placeholder="Address 1" value={customer.address?.state} onChange={e => setCustomer({...customer,address:{...customer.address,state:e.target.value}})}/></Flex>
        <Flex textAlign="left" flex="1"><Label>Zip</Label><br /><Input type="text" placeholder="Address 1" value={customer.address?.zip} onChange={e => setCustomer({...customer,address:{...customer.address,zip:e.target.value}})}/></Flex>
       
    </Flexed>

    <Flexed gap="15px" padding="20px 0px 0px 0px" >
    <Flex flex="1" textAlign="left" ><Label>Main Phone Number</Label><br /><Input type="text" placeholder="Main Phone Number" value={customer.mainphone} onChange={e => setCustomer({...customer,mainphone:e.target.value})} /></Flex>
    <Flex textAlign="left" flex=".5"><Label>EXT</Label><br /><Input type="text" placeholder="EXT" value={customer.ext} onChange={e => setCustomer({...customer,ext:e.target.value})}/></Flex>
        <Flex textAlign="left" flex="1"><Label>Fax</Label><br /><Input type="text" placeholder="Fax" value={customer.fax} onChange={e => setCustomer({...customer,fax:e.target.value})} /></Flex>
  
    </Flexed>
    <Flexed padding="20px 0px 0px 0px">
    <Flex textAlign="left" ><Label>Website</Label><br /><Input type="text" placeholder="Website" value={customer.website} onChange={e => setCustomer({...customer,website:e.target.value})} /></Flex>
</Flexed>
</Flex>
<Flex  flex="1"  textAlign="left" ><Label>Customer Notes</Label><br /><Textarea rows="9" onChange={e => setCustomer({...customer,notes:e.target.value})}></Textarea></Flex>
</Flexed>

<Flexed padding="20px 0px 0px 0px" gap="10px">
    <Flex textAlign="left" ><Label>Tax Exempt</Label><br /><Selectbox onChange={e => setCustomer({...customer,taxexempt:e.target.value})}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </Selectbox></Flex>
      
    <Flex textAlign="left" >
    <Label>Billing Terms</Label><br /><Selectbox onChange={e => setCustomer({...customer,billingterms:e.target.value})}>
    <option value="Net 30" selected>Net 30</option>
<option value="1/10 Net 30" >1/10 Net 30</option>
<option value="2/10 Net 30" >2/10 Net 30</option>
<option value="1/2% 30 Days" >1/2% 30 Days</option>
<option value="Net 15" >Net 15</option>
<option value="Net 45" >Net 45</option>
<option value="C.O.D" >C.O.D</option>
        </Selectbox>
    </Flex>
    <Flex textAlign="left" ><Label>Customer Discount Rate</Label><br /><Input type="text" placeholder="Discount Rate" /></Flex>
</Flexed>
<Flexed padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex><Selectbox value={customer.department} onChange={e => setCustomer({...customer,department:e.target.value})}>
<option value="MPS">MailPost</option>
    <option value="PLS">PLS Print</option>

    </Selectbox></Flex> <Flex> <input
          type="checkbox"
          checked={isChecked}
          onChange={handleOnChange}
        />
        &nbsp;&nbsp;{isChecked ? "Customer" : "Change to Customer"}</Flex><Flex textAlign="right"><Button style={{zIndex:"10"}} type="submit">Save New Customer</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default AddCustomer
