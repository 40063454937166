import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Flex, Flexed, Label, Scroll, Teir, Wrap } from '../../shared'
import { recentColumns,leaseColumns,rentalColumns } from '../components/Columns'
import RecentTable from '../components/RecentTable'





const Main = () => {

    const [allpurchases, setAllpurchases] = useState([])
    const [allleases, setAllleases] = useState([])
    const [list, setList] = useState('')



    useEffect(() => {
const getdatastuff = async() => {
  //await axios.get(`https://mailpostapi.herokuapp.com/supplies/getall`)
  await axios.get(`https://mailpostapi.herokuapp.com/supplies/getall`)
  .then(res => {console.log(res.data);setAllpurchases(res.data)})
  //.then(res => {setMainlocation(res.data.address.filter(item => item.main === "1"))})
  .catch(err => {
      console.log(err)
  })
}

const getleasestuff = async() => {
  await axios.get(`https://mailpostapi.herokuapp.com/leases/allleases`)
  .then(res => {setAllleases(res.data)})
  //.then(res => {setMainlocation(res.data.address.filter(item => item.main === "1"))})
  .catch(err => {
      console.log(err)
  })
}

                    getdatastuff()    
                    getleasestuff()        
    },[])  



 console.log(allleases)


  return (
    <Teir>
<Flexed padding="20px 0px" fontSize="calc(.6rem + .5vw)">
    <Flex fontSize="calc(1.2rem + .5vw)">
      Recent Activities
    </Flex>
</Flexed>
<Wrap margin="20px 0px" padding="10px 0px" border="1px solid #303030">
  <Label padding="20px 10px" fontWeight="bold" fontSize="calc(1rem + .5vw)" color="var(--plsBlue)">Purchases</Label>

<RecentTable supplyColumns={recentColumns} DATA={allpurchases} setList={setList} setter="customer/purchase"/>

</Wrap>
<Wrap margin="20px 0px" border="1px solid #303030" padding="10px 0px">
  <Label padding="10px" fontWeight="bold"  fontSize="calc(1rem + .5vw)" color="var(--plsBlue)">Leases</Label>
  <RecentTable supplyColumns={leaseColumns} DATA={allleases} setList={setList} setter="customer/leases"/>

</Wrap>
<Wrap margin="20px 0px" border="1px solid #303030" padding="10px 0px">
  <Label padding="10px" fontWeight="bold"  fontSize="calc(1rem + .5vw)" color="var(--plsBlue)">Rentals</Label>
  <RecentTable supplyColumns={rentalColumns} DATA={allpurchases} setList={setList} setter="customer/rental"/>

</Wrap>
</Teir>
  )
}

export default Main
