import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Calender from '../Calander/Calender';
import M110 from '../Calander/M110';
import Digital from '../Calander/Digital';
import Anicolor from '../Calander/Anicolor';
const Schedules = () => {
  return (
    <Tabs>
    <TabList>
      <Tab>Press Schedule</Tab>
      <Tab>Cutter Schedule</Tab>
      <Tab>Mailing Schedule</Tab>
    </TabList>

    <TabPanel><br />
      {/*/////////////////////////////////////////PRESS////////////////////////////////////////////////////////////////////*/}
      <Tabs>
    <TabList>
      <Tab>Anicolor</Tab>
      <Tab>GL40</Tab>
      <Tab>M110</Tab>
      <Tab>Digital</Tab>
    </TabList>

    <TabPanel>
    <Anicolor />
    </TabPanel>
    <TabPanel>
    <Calender />
    </TabPanel>
    <TabPanel>
    <M110 />
    </TabPanel>
    <TabPanel>
    <Digital />
    </TabPanel>
  </Tabs>
      {/*////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
    </TabPanel>
    <TabPanel>
      <h2>Any content 2</h2>
    </TabPanel>
    <TabPanel>
      <h2>Any content 2</h2>
    </TabPanel>
  </Tabs>
  )
}

export default Schedules