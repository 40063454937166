import React from 'react'
import { Input } from '../../shared'


const GlobalFilter = ({filter,setFilter}) => {
  return (
    <span style={{padding:"20px 0px"}}>
        <Input type="text" value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search:"
        width="80%"
        
        />

    </span>
  )
}

export default GlobalFilter